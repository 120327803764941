import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { any, bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { propTypes } from '../../../util/types';
import {
  Avatar,
  Button,
  InlineTextButton,
  Logo,
  Modal,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
} from '../../../components';

import TopbarSearchFormCustom from '../TopbarSearchFormCustom/TopbarSearchFormCustom';

import css from './TopbarDesktop.module.css';

const TopbarDesktop = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentMarket, setCurrentMarket] = useState('');

  const {
    className,
    appConfig,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    onManageDisableScrolling,
  } = props;

  useEffect(() => {
    // Function to extract the current market from the URL
    const getCurrentMarket = () => {
      const currentPath = window.location.pathname;
      const market = currentPath.split('/')[1]; // Extract the market from the pathname
      setCurrentMarket(market);
    };

    getCurrentMarket();
  }, []);

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;
  const UserID = currentUser?.id?.uuid;
  const username = currentUser?.attributes?.profile?.publicData?.username;

  const classes = classNames(rootClassName || css.root, className);

  const search = (
    <TopbarSearchFormCustom
      className={css.searchLinkDesktop}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      appConfig={appConfig}
    />
  );

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.customMenuLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.customMenu}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const modal = onManageDisableScrolling ? (
    <Modal
      id="authenticateUserPopup"
      isOpen={isModalOpen}
      onClose={() => {
        setIsModalOpen(false);
      }}
      usePortal
      contentClassName={css.modalContent}
      onManageDisableScrolling={onManageDisableScrolling}
    >
      <h2 className={css.modalTitle}>
        <FormattedMessage id="AuthenticationModal.title" />
      </h2>
      <div className={css.modalDescription}>
        <p>
          <FormattedMessage id="AuthenticationModal.Description" />
        </p>
        <Button
          onClick={() => {
            setIsModalOpen(false);
            window.location.href = '/login';
          }}
        >
          <FormattedMessage id="AuthenticationModal.Button" />
        </Button>
      </div>
    </Modal>
  ) : null;

  const authenticatePopUp = (e) => {
    e.preventDefault();
    !authenticatedOnClientSide ? setIsModalOpen(true) : setIsModalOpen(false);
  };

  const authenticatetoSell = authenticatedOnClientSide ? (
    <NamedLink className={css.customMenuLink} name="NewListingPage">
      <span className={css.customMenu}>
        <FormattedMessage id="TopbarDesktop.createListing" />
      </span>
    </NamedLink>
  ) : (
    <Link className={css.customMenuLink} to={'#'} onClick={authenticatePopUp}>
      <span className={css.customMenu}>
        <FormattedMessage id="TopbarDesktop.createListing" />
      </span>
    </Link>
  );

  const currentPageClass = (page) => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="InboxPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('InboxPage'))}
            name="InboxPage"
            params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.messages" />
          </NamedLink>
        </MenuItem>
        {username ? (
          <MenuItem key="ProfilePage">
            <NamedLink
              className={classNames(css.profileLink, currentPageClass('ProfilePage'))}
              name="ProfilePage"
              params={{ id: username }}
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.viewProfileLink" />
            </NamedLink>
          </MenuItem>
        ) : (
          <MenuItem key="ProfilePage">
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.viewProfileLink" />
          </MenuItem>
        )}
        <MenuItem key="FavouriteListingsPage">
          <NamedLink
            className={classNames(css.favouriteListingsLink, currentPageClass('FavouriteListingsPage'))}
            name="FavouriteListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.favourites" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ManageListingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourlistings" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.customMenuLink}>
      <span className={css.customMenu}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  return (
    <nav className={classes}>
      <div className={css.menu}>
        <div className={css.menuItem}>
          <NamedLink
            className={classNames(css.customMenuLink, {
              [css.activeMenu]: currentMarket === 'womens', // Apply the activeMenu class if the market is 'women'
            })}
            name="WomenPage"
          >
            <span className={css.customMenu}>
              <FormattedMessage id="TopbarDesktop.Women" />
            </span>
          </NamedLink>
          <NamedLink
            className={classNames(css.customMenuLink, {
              [css.activeMenu]: currentMarket === 'neutral', // Apply the activeMenu class if the market is 'neutral'
            })}
            name="NeutralPage"
          >
            <span className={css.customMenu}>
              <FormattedMessage id="TopbarDesktop.Neutral" />
            </span>
          </NamedLink>
          <NamedLink
            className={classNames(css.customMenuLink, {
              [css.activeMenu]: currentMarket === 'mens', // Apply the activeMenu class if the market is 'mens'
            })}
            name="MensPage"
          >
            <span className={css.customMenu}>
              <FormattedMessage id="TopbarDesktop.Mens" />
            </span>
          </NamedLink>
          <NamedLink
            className={classNames(css.customMenuLink, {
              [css.activeMenu]: currentMarket === 'art', // Apply the activeMenu class if the market is 'art'
            })}
            name="ArtPage"
          >
            <span className={css.customMenu}>
              <FormattedMessage id="TopbarDesktop.Art" />
            </span>
          </NamedLink>
          <div className={css.customMenuLink}>
            <span className={css.customMenu}>{search}</span>
          </div>
        </div>
      </div>
      <div className={`${css.menu} ${css.logo}`}>
        <div className={`${css.menuItem} ${css.LogoMenu}`}>
          <NamedLink className={css.logoLink} name="SearchPage">
            <span className={css.logo}>
              <Logo
                format="desktop"
                className={css.logo}
                alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
              />
            </span>
          </NamedLink>
        </div>
      </div>
      <div className={`${css.menu} ${css.rightMenu}`}>
        <div className={css.menuItem}>
          {authenticatetoSell}
          <NamedLink className={css.customMenuLink} name="AboutPage">
            <span className={css.customMenu}>
              <FormattedMessage id="TopbarDesktop.about" />
            </span>
          </NamedLink>
          {profileMenu}
          {signupLink}
          {modal}
        </div>
      </div>
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  onManageDisableScrolling: null,
  appConfig: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool,
  onLogout: func,
  notificationCount: number,
  onSearchSubmit: func,
  initialSearchFormValues: object,
  onManageDisableScrolling: func,
  intl: intlShape,
  appConfig: object,
};

export default TopbarDesktop;