import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './Topbar.module.css';
import userIcon from './user.png';

const ProfileIcon = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.rootMenuIcon, className);

  return (
   <img src={userIcon}></img>
  );
};

const { string } = PropTypes;

ProfileIcon.defaultProps = {
  className: null,
  rootClassName: null,  
};

ProfileIcon.propTypes = {
  className: string,
  rootClassName: string,
};

export default ProfileIcon;
