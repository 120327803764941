function returnList(item, forcheck) {
    //console.log("returnList", item, forcheck, (item == forcheck));
    return item == forcheck;
}

export const compareDate = (date1, date2) => {
    return (date1.getTime() - date2.getTime() < 0);
}

export const returnProviderReaction = (transaction_id , provider) => {
    return provider?.attributes?.profile?.publicData?.offerReactions?.filter(reaction => returnList(reaction.txid, transaction_id))[0];
}

export const checkTransactionState = (transaction_id , customer, provider, currentUserId) => {
    const offer = customer?.attributes?.profile?.publicData?.offers?.filter(offer => returnList(offer.txid, transaction_id))[0];

    // check if transaction has offer and offer is not expired
    if(offer?.txid == transaction_id && compareDate(new Date(), new Date(offer.expires))) {
        const reaction = returnProviderReaction(transaction_id, provider);
        if (reaction?.reaction == 'accepted') {
            return 'OFFER ACCEPTED';
        } 
        else if (reaction?.reaction == 'declined') {
            return 'OFFER DECLINED';
        }
        else if (currentUserId == provider?.id?.uuid) {
            return 'OFFER PENDING';
        }
        return "NEW OFFER";
    }
    else if(offer?.txid == transaction_id && !compareDate(new Date(), new Date(offer?.expires))) {
        return "OFFER EXPIRED";
    }
    else {
        return "NO OFFER";
    }
};

export const checkOffersForTransaction = (transaction_id , customer) => {
    const offer = customer?.attributes?.profile?.publicData?.offers?.filter(offer => returnList(offer.txid, transaction_id))[0];

    // check if transaction has offer and offer is not expired
    if(offer?.txid == transaction_id && compareDate(new Date(), new Date(offer.expires))) {
        return true;
    }
    return false;
};


// update offer if still on the same page
export const updateCustomerOffer = (transaction_id , customer, newOfferAmount) => {
    let offerIndex = customer?.attributes?.profile?.publicData?.offers
    ?.findIndex(offer => returnList(offer.txid, transaction_id));

    customer.attributes.profile.publicData.offers[offerIndex].offered_amount = newOfferAmount;
    customer.attributes.profile.publicData.offers[offerIndex].expires = returnOfferExpiryDate();
    return customer.attributes.profile.publicData.offers;
};

export const addNewCustomerOffer = (transaction, customer, newOfferAmount) => {
    let offerIndex = customer?.attributes?.profile?.publicData?.offers?.length;

    const offerObject = {
        "provider_id": transaction.provider.id.uuid,
        "offered_amount": newOfferAmount,
        "listing_id": transaction.listing.id.uuid,
        "expires": returnOfferExpiryDate(),
        "txid": transaction.id.uuid,
        "state":"unused"
      }

    if (!offerIndex) {
        customer.attributes.profile.publicData.offers = [offerObject];
    } else {
        customer.attributes.profile.publicData.offers[offerIndex] = offerObject;
    }

    return customer.attributes.profile.publicData.offers;
};



export const returnCurrentDate = () => {
    const today = new Date();
    const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate()+' '+(today.getHours()+1)+':'+today.getMinutes()+':'+today.getSeconds();
    return date;
};

export const returnOfferExpiryDate = () => {
    const today = new Date();
    today.setDate(today.getDate() + 1);
    const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate()+' '+(today.getHours()+1)+':'+today.getMinutes()+':'+today.getSeconds();
    return date;
};

export const returnOffer = (transaction_id , customer) => {
    return customer?.attributes?.profile?.publicData?.offers?.filter(offer => returnList(offer.txid, transaction_id))[0];
}

// for checking if offer is accepted or declined
export const checkOfferAndReactionState = (transaction_id , customer, provider) => {
    const offer = customer?.attributes?.profile?.publicData?.offers
    ?.filter(offer => returnList(offer.txid, transaction_id))[0];

    const reaction = provider?.attributes?.profile?.publicData?.offerReactions
    ?.filter(reaction => returnList(reaction.txid, transaction_id))[0];

    // check if transaction has offer and offer is not expired
    if(offer?.txid == transaction_id 
        && compareDate(new Date(), new Date(offer.expires))
        && !(reaction?.txid == transaction_id)) {
        return true;
    }
    return false;
};

export const checkOfferForListing = (listing_id, provider, currentUser) => {
    const offerRequests = currentUser?.attributes?.profile?.publicData?.offers
        ?.filter(offer => returnList(offer?.listing_id, listing_id))
        ?.filter(offer => returnList(offer?.state, 'unused'))//;
        ?.filter(offer => compareDate(new Date(), new Date(offer?.expires)));
    
    let offeredAmount = undefined;

    offerRequests?.forEach(offer => {
        const reaction = provider?.attributes?.profile?.publicData?.offerReactions
            ?.filter(reaction => returnList(reaction?.txid, offer?.txid))
            ?.filter(reaction => returnList(reaction?.reaction, 'accepted'))[0];

        if(reaction) {
            offeredAmount = offer?.offered_amount;
            return;
        }
    });

    return offeredAmount;
}

// update the listing state to used
export const updateUsedCustomerOffer = (listing_id , customer, newState, offer_price) => {
    let offerIndex = customer?.attributes?.profile?.publicData?.offers
    ?.findIndex(offer => (returnList(offer.state, 'unused') 
        && returnList(offer.offered_amount, offer_price)
        && returnList(offer.listing_id, listing_id)
        && compareDate(new Date(), new Date(offer.expires))
    )); 

    let offerFound = false;

    if (offerIndex != -1) {
        offerFound = true;
        customer.attributes.profile.publicData.offers[offerIndex].state = newState;
    }

    return [customer.attributes.profile.publicData.offers, offerFound];
};
