export const checkHomeUtil = (windowLocationSearch) => {
  if (windowLocationSearch?.includes('meta_featured')) {
    return true;
  }

  if (windowLocationSearch?.includes('keywords=')) {
    return null;
  }

  return false;
};
