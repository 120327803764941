import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { twitterPageURL } from '../../util/urlHelpers';
import config from '../../config';
import {
  IconSocialMediaInstagram,
  Logo,
  ExternalLink,
  NamedLink,
} from '../../components';

import css from './Footer.module.css';
import { Link } from 'react-router-dom';

const renderSocialMediaLinks = intl => {
  const { siteInstagramPage } = config;

  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });

  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInsta}
    >
      <IconSocialMediaInstagram />
    </ExternalLink>
  ) : null;

  return [instragramLink].filter(v => v != null);
};

const Footer = props => {
  const { rootClassName, className, intl } = props;
  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.topBorderWrapper}>
        <div className={css.content}>
          <div className={css.extraLinks}>
            <p className={css.organizationDescription}>
              <NamedLink name="AboutPage" to={{ hash: '#contact' }} className={css.legalLink}>
                <FormattedMessage id="Footer.toContactPage" />
              </NamedLink>
              <ExternalLink href="https://www.instagram.com/embryoglobal/" className={css.legalLink}>
                <FormattedMessage id="Footer.instagram" />
              </ExternalLink>
            </p>
          </div>
          <div className={css.legalMatters}>
            <p className={css.organizationDescription}>
              <NamedLink name="LandingPage" className={css.legalLink}>
                <FormattedMessage id="Footer.copyright" />
              </NamedLink>
              <NamedLink name="TermsOfServicePage" className={css.legalLink}>
                <FormattedMessage id="Footer.termsOfUse" />
              </NamedLink>
              <NamedLink name="PrivacyPolicyPage" className={css.legalLink}>
                <FormattedMessage id="Footer.privacyPolicy" />
              </NamedLink>
              <NamedLink name="AboutPage" className={css.legalLink}>
                <FormattedMessage id="Footer.cookies" />
              </NamedLink>
              <NamedLink name="AboutPage" className={css.legalLink}>
                <FormattedMessage id="Footer.accessibility" />
              </NamedLink>
            </p>
          </div>
          <div className={css.copyrightAndTermsMobile}>
            <NamedLink name="LandingPage" className={css.organizationCopyrightMobile}>
              <FormattedMessage id="Footer.copyright" />
            </NamedLink>
            <div className={css.tosAndPrivacyMobile}>
              <NamedLink name="PrivacyPolicyPage" className={css.privacy}>
                <FormattedMessage id="Footer.privacy" />
              </NamedLink>
              <NamedLink name="TermsOfServicePage" className={css.terms}>
                <FormattedMessage id="Footer.terms" />
              </NamedLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(Footer);
