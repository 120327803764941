const REACT_APP_PAYPAL_CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID;
const PAYPAL_CLIENT_SECRET = process.env.PAYPAL_CLIENT_SECRET;
const REACT_APP_PAYPAL_SANDBOX_BASE_URL = process.env.REACT_APP_PAYPAL_SANDBOX_BASE_URL;
const REACT_APP_PAYPAL_LIVE_BASE_URL = process.env.REACT_APP_PAYPAL_LIVE_BASE_URL;
const REACT_APP_PAYPAL_MODE = process.env.REACT_APP_PAYPAL_MODE;
const REACT_APP_SANDBOX_PAYPAL_MERCHANT_ID = process.env.REACT_APP_SANDBOX_PAYPAL_MERCHANT_ID;
const REACT_APP_LIVE_PAYPAL_MERCHANT_ID = process.env.REACT_APP_LIVE_PAYPAL_MERCHANT_ID;

const paypal = {
  ClientId: REACT_APP_PAYPAL_CLIENT_ID,
  ClientSecret: PAYPAL_CLIENT_SECRET,
  Mode: REACT_APP_PAYPAL_MODE,
  SandboxBaseURL: REACT_APP_PAYPAL_SANDBOX_BASE_URL,  
  SandBoxMerchantID: REACT_APP_SANDBOX_PAYPAL_MERCHANT_ID,
  LiveBaseURL: REACT_APP_PAYPAL_LIVE_BASE_URL,
  LiveMerchantID: REACT_APP_LIVE_PAYPAL_MERCHANT_ID,
}

export default paypal;