import React, { Component, useState, useEffect, setstate } from 'react';
import { string, func, bool } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { AspectRatioWrapper, NamedLink, ResponsiveImage, Modal, Button } from '../../components';
import partnericonw from '../../assets/partnericonw.png';


import css from './ListingCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = props => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
    showSizeInfo,
    onManageDisableScrolling,
    isAuthenticated,
    currentUser,
    onUpdateCurrentUserProfile,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price } = currentListing.attributes;
  const size = currentListing?.attributes?.publicData?.size;
  let sizes = String(size);
  // remove the last comma from the string
  sizes = String(sizes).replace(/,\s*$/, "");
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorID = author.id.uuid;
  let authorName = author.attributes.profile?.publicData?.username;
  const verified = author.attributes.profile?.publicData?.verified || false;
  authorName = authorName?.replace(/ /g,"");

  const Heart = ({ size = 20, color = '#b2b2b2' }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path>
    </svg>
  );
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const { aspectWidth = 2, aspectHeight = 3, variantPrefix = 'listing-card' } = config.listing;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const { formattedPrice, priceTitle } = priceData(price, intl);
  const unitType = config.lineItemUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'ListingCard.perNight'
    : isDaily
    ? 'ListingCard.perDay'
    : 'ListingCard.perUnit';

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  // const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const favModal = onManageDisableScrolling ? (
    <Modal
      id="authenticateUserPopup"
      isOpen={isModalOpen}
      onClose={() => {
        setIsModalOpen(false);
      }}
      usePortal
      contentClassName={css.modalContent}
      onManageDisableScrolling={onManageDisableScrolling}
    >
      <h2 className={css.modalTitle}>
        <FormattedMessage id="AuthenticationModal.title" />
      </h2>
      <div className={css.modalDescription}>
        <p>
          <FormattedMessage id="AuthenticationModal.Description" />
        </p>
        <Button
          onClick={() => {
            setIsModalOpen(false);
            window.location.href = '/login';
          }}
        >
          <FormattedMessage id="AuthenticationModal.Button" />
        </Button>
      </div>
    </Modal>
  ) : null;

  let favouriteCSS = css.heartIcon;
  const favourites = currentUser?.attributes?.profile?.protectedData?.favouriteListings
    ? currentUser.attributes.profile.protectedData.favouriteListings
    : [];

  if (authenticatedOnClientSide && favourites) {
    favouriteCSS = favourites.includes(id) ? css.heartIconLiked : css.heartIcon;
  }

  const addListingToCurrentUserFavourites = e => {
    e.preventDefault();

    if (authenticatedOnClientSide) {
      let newFavourites = favourites;
      let protectedData = currentUser.attributes.profile.protectedData;

      // if favouriteListings is empty or undefined, add the listing to it
      if (!favourites && !favourites.includes(id)) {
        newFavourites = [...favourites, listing.id.uuid];
        protectedData = { ...protectedData, favouriteListings: newFavourites };
      } else if (!favourites.includes(id)) {
        newFavourites = [...favourites, listing.id.uuid];
        protectedData = { ...protectedData, favouriteListings: newFavourites };
      } else {
        newFavourites = favourites.filter(f => f !== listing.id.uuid);
        protectedData = { ...protectedData, favouriteListings: newFavourites };
      }

      onUpdateCurrentUserProfile ? onUpdateCurrentUserProfile({ protectedData }) : null;
      setIsModalOpen(false);
    } else {
      setIsModalOpen(true);
    }
  };

  return (
    <div>
      <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
        <AspectRatioWrapper
          className={css.aspectRatioWrapper}
          width={aspectWidth}
          height={aspectHeight}
          {...setActivePropsMaybe}
        >
          <LazyImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={['embryoStyle', 'embryoStyle2x']}
            //variants={variants}
            sizes={renderSizes}
          />
          <div
            className={css.listingLikeIcon}
            onClick={addListingToCurrentUserFavourites.bind(this)}
          >
            <svg
              className={favouriteCSS}
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#000000"
              strokeWidth="1"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path>
            </svg>
          </div>
        </AspectRatioWrapper>
      </NamedLink>
      <div className={css.info}>
        <div className={css.mainInfo}>
          <NamedLink
            className={css.authorNameLink}
            name="ProfilePage"
            params={{ id: authorName, slug }}
          >            
            {showAuthorInfo ? 
              <div className={css.authorInfo}>{authorName?.toUpperCase()}
                {verified?
                  <img className={css.verifiedBarge} src={partnericonw} alt="verified_barged" height={8}/>
                : null}
              </div> 
            : null}
          </NamedLink>
          <div className={css.priceValue} title={priceTitle}>
            {formattedPrice}
          </div>
          {config.listing.showUnitTypeTranslations ? (
            <div className={css.perUnit}>
              <FormattedMessage id={unitTranslationKey} />
            </div>
          ) : null}
        </div>
        <div className={css.price}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
          {showSizeInfo ? (
            <div className={css.sizeInfo}>
              {sizes}
            </div>
          ) : null}
        </div>
      </div>
      {favModal}
    </div>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
  showSizeInfo: true,
  user: true,
  onManageDisableScrolling: null,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,
  showSizeInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
  onManageDisableScrolling: func,
};

export default injectIntl(ListingCardComponent);