import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { any, bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { propTypes } from '../../../util/types';
import {
  Avatar,
  Button,
  InlineTextButton,
  Logo,
  Modal,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
} from '../../../components';

import TopbarSearchFormCustom from '../TopbarSearchFormCustom/TopbarSearchFormCustom';

import css from './TopbarDesktop.module.css';

const TopbarDesktop = props => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    className,
    appConfig,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    onManageDisableScrolling,
  } = props;

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;
  const UserID = currentUser?.id?.uuid;

  const classes = classNames(rootClassName || css.root, className);

  const search = (
    <TopbarSearchFormCustom
      className={css.searchLinkDesktop}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      appConfig={appConfig}
    />
  );

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  /* var searchTerm = null;

  const changeSearchText = () => {
    const getSearchUrl = window?.location.href.includes('/s')? window.location.href : null;
    const url = getSearchUrl? new URL(getSearchUrl) : null;
    searchTerm = url?.searchParams.get("keywords");
  };

  mounted? changeSearchText() : null; */

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.customMenuLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.customMenu}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const modal = onManageDisableScrolling ? (
    <Modal
      id="authenticateUserPopup"
      isOpen={isModalOpen}
      onClose={() => {
        setIsModalOpen(false);
      }}
      usePortal
      contentClassName={css.modalContent}
      onManageDisableScrolling={onManageDisableScrolling}
    >
      <h2 className={css.modalTitle}>
        <FormattedMessage id="AuthenticationModal.title" />
      </h2>
      <div className={css.modalDescription}>
        <p>
          <FormattedMessage id="AuthenticationModal.Description" />
        </p>
        <Button
          onClick={() => {
            setIsModalOpen(false);
            window.location.href = '/login';
          }}
        >
          <FormattedMessage id="AuthenticationModal.Button" />
        </Button>
      </div>
    </Modal>
  ) : null;

  const authenticatePopUp = e => {
    e.preventDefault();
    !authenticatedOnClientSide ? setIsModalOpen(true) : setIsModalOpen(false);
  };

  const authenticatetoSell = authenticatedOnClientSide ? (
    <NamedLink className={css.customMenuLink} name="NewListingPage">
      <span className={css.customMenu}>
        <FormattedMessage id="TopbarDesktop.createListing" />
      </span>
    </NamedLink>
  ) : (
    <Link className={css.customMenuLink} to={'#'} onClick={authenticatePopUp}>
      <span className={css.customMenu}>
        <FormattedMessage id="TopbarDesktop.createListing" />
      </span>
    </Link>
  );

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  return (
    <nav className={classes}>
      <div className={css.menu}>
        <div className={css.menuItem}>
          <NamedLink className={css.customMenuLink} name="WomenPage">
            <span className={css.customMenu}>
              <FormattedMessage id="TopbarDesktop.Women" />
            </span>
          </NamedLink>
          <NamedLink className={css.customMenuLink} name="NeutralPage">
            <span className={css.customMenu}>
              <FormattedMessage id="TopbarDesktop.Neutral" />
            </span>
          </NamedLink>
          <NamedLink className={css.customMenuLink} name="MensPage">
            <span className={css.customMenu}>
              <FormattedMessage id="TopbarDesktop.Mens" />
            </span>
          </NamedLink>
          <NamedLink className={css.customMenuLink} name="ArtPage">
            <span className={css.customMenu}>
              <FormattedMessage id="TopbarDesktop.Art" />
            </span>
          </NamedLink>
          <div className={css.customMenuLink}>
            {/* <NamedLink className={css.customMenuLink} name="SearchPage"> */}
            <span className={css.customMenu}>
              {/*  {searchTerm ? searchTerm.toUpperCase() : <FormattedMessage id="TopbarDesktop.Search" />} */}
            </span>
            {/* </NamedLink> */}
          </div>

          <div className={css.customAbout}>
            <div className={css.customMenuTitle}>
              <span className={css.customMenu}>
              <NamedLink className={css.customMenuLink} name="AboutPage">
            <span className={css.customMenu}>
              <FormattedMessage id="TopbarDesktop.about" />
            </span>
          </NamedLink>
              </span>
            </div>
            <NamedLink className={css.customAboutMenuLink} name="TermsOfServicePage">
              <span className={css.customAboutMenu}>
                <FormattedMessage id="TermsOfServicePage.heading" />
              </span>
            </NamedLink>
            <NamedLink className={css.customAboutMenuLink} name="PrivacyPolicyPage">
              <span className={css.customAboutMenu}>
                <FormattedMessage id="PrivacyPolicyPage.heading" />
              </span>
            </NamedLink>
          </div>
        </div>
      </div>
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  onManageDisableScrolling: null,
  appConfig: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool,
  onLogout: func,
  notificationCount: number,
  onSearchSubmit: func,
  initialSearchFormValues: object,
  onManageDisableScrolling: func,
  intl: intlShape,
  appConfig: object,
};

export default TopbarDesktop;
