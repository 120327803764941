import pick from 'lodash/pick';
import { currentUserShowSuccess, fetchCurrentUser } from '../../ducks/user.duck';

import config from '../../config';

import { denormalisedResponseEntities, ensureCurrentUser } from '../../util/data';

const axios = require('axios').default;

import { updateListingFavoritesOnProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import { storableError } from '../../util/errors';

import { loadScript, loadCustomScript } from "@paypal/paypal-js";
import paypal from '../../config/paypal-config';

// ================ Action types ================ //
export const SET_INITIAL_VALUES = 'app/PayPalPayoutPage/SET_INITIAL_VALUES';
export const SAVE_ACCESS_TOKEN_REQUEST = 'app/PayPalPayoutPage/SAVE_ACCESS_TOKEN_REQUEST';
export const SAVE_ACCESS_TOKEN_EXPIRED = 'app/PayPalPayoutPage/SAVE_ACCESSTOKEN_EXPIRED';
export const SAVE_ACCESS_TOKEN_REQUEST_SUCCESS = 'app/PayPalPayoutPage/SAVE_ACCESS_TOKEN_REQUEST_SUCCESS';
export const SAVE_ACCESS_TOKEN_REQUEST_ERROR = 'app/PayPalPayoutPage/SAVE_ACCESS_TOKEN_REQUEST_ERROR';

export const SAVE_PAYOUT_DETAILS_REQUEST = 'app/PayPalPayoutPage/SAVE_PAYOUT_DETAILS_REQUEST';
export const SAVE_PAYOUT_DETAILS_SUCCESS = 'app/PayPalPayoutPage/SAVE_PAYOUT_DETAILS_SUCCESS';
export const SAVE_PAYOUT_DETAILS_ERROR = 'app/PayPalPayoutPage/SAVE_PAYOUT_DETAILS_ERROR';

export const SAVE_SIGNUP_LINK_REQUEST = 'app/PayPalPayoutPage/SAVE_SIGNUP_LINK_REQUEST';
export const SAVE_SIGNUP_LINK_REQUEST_SUCCESS = 'app/PayPalPayoutPage/SAVE_SIGNUP_LINK_REQUEST_SUCCESS';
export const SAVE_SIGNUP_LINK_REQUEST_ERROR = 'app/PayPalPayoutPage/SAVE_SIGNUP_LINK_REQUEST_ERROR';

export const SAVE_TRACKING_REQUEST = 'app/PayPalPayoutPage/SAVE_TRACKING_REQUEST';
export const SAVE_TRACKING_REQUEST_SUCCESS = 'app/PayPalPayoutPage/SAVE_TRACKING_REQUEST_SUCCESS';
export const SAVE_TRACKING_REQUEST_ERROR = 'app/PayPalPayoutPage/SAVE_TRACKING_REQUEST_ERROR';
export const SAVE_PAYPAL_ACCOUNT_INVALID = 'app/PayPalPayoutPage/SAVE_PAYPAL_ACCOUNT_INVALID';

export const PAYPAL_CHECK_REQUEST = 'app/PayPalPayoutPage/PAYPAL_CHECK_REQUEST';
export const PAYPAL_CHECK_SUCCESS = 'app/PayPalPayoutPage/PAYPAL_CHECK_SUCCESS';
export const PAYPAL_CHECK_ERROR = 'app/PayPalPayoutPage/PAYPAL_CHECK_ERROR';

// ================ Reducer ================ //

const initialState = {
    payoutDetailsSaveInProgress: false,
    payoutDetailsSaved: false,
    fromReturnURL: false,
    userOnboarded: false,
    accessTokenResponse: {},
    signUpLinks: [],
    currentUser: {},
    payPalAPIRootURL: config?.payPal?.baseURL,
    payPalCheckInProgress: false,
    isPayPalAccountConnected: false,
    paypalVerification: {
      payments_receivable: false,
      primary_email_confirmed: false,
      oauth_integrations: [],
    }
  };

export default function reducer(state = initialState, action = {}) {
    const { type, payload } = action;
    switch (type) {
      case SET_INITIAL_VALUES:
        return { ...initialState, ...payload };

      case PAYPAL_CHECK_REQUEST:
        return { ...state, payPalCheckInProgress: true };
      case PAYPAL_CHECK_SUCCESS:
        return { ...state, payPalCheckInProgress: false, ...payload };
      case PAYPAL_CHECK_ERROR:
        return { ...state, payPalCheckInProgress: false, isPayPalAccountConnected: false };

      case SAVE_ACCESS_TOKEN_REQUEST:
        return { ...state, payoutDetailsSaveInProgress: true };
      case SAVE_ACCESS_TOKEN_EXPIRED:
        return { ...state, payoutDetailsSaveInProgress: false };
      case SAVE_ACCESS_TOKEN_REQUEST_SUCCESS:
        return { ...state, payoutDetailsSaveInProgress: false, ...payload };
      case SAVE_ACCESS_TOKEN_REQUEST_ERROR:
        return { ...state, payoutDetailsSaveInProgress: false };
      case SAVE_TRACKING_REQUEST:
        return { ...state, payoutDetailsSaveInProgress: true };
      case SAVE_TRACKING_REQUEST_SUCCESS:
        return { ...state, payoutDetailsSaveInProgress: false, ...payload };
      case SAVE_TRACKING_REQUEST_ERROR:
        return { ...state, payoutDetailsSaveInProgress: false };
      case SAVE_PAYPAL_ACCOUNT_INVALID:
        return { ...state, payoutDetailsSaveInProgress: false };
      case SAVE_SIGNUP_LINK_REQUEST:
        return { ...state, payoutDetailsSaveInProgress: true };
      case SAVE_SIGNUP_LINK_REQUEST_SUCCESS:
        return { ...state, payoutDetailsSaveInProgress: false, signUpLinks: payload };
      case SAVE_SIGNUP_LINK_REQUEST_ERROR:
        return { ...state, payoutDetailsSaveInProgress: false };
      case SAVE_PAYOUT_DETAILS_REQUEST:
        return { ...state, payoutDetailsSaveInProgress: true };
      case SAVE_PAYOUT_DETAILS_ERROR:
        return { ...state, payoutDetailsSaveInProgress: false };
      case SAVE_PAYOUT_DETAILS_SUCCESS:
        return { ...state, payoutDetailsSaveInProgress: false, payoutDetailsSaved: true };
  
      default:
        return state;
    }
  }

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
    type: SET_INITIAL_VALUES,
    payload: pick(initialValues, Object.keys(initialState))
  });

  export const payPalCheckRequest = () => ({
    type: PAYPAL_CHECK_REQUEST,
  });

  export const payPalCheckSuccess = (payload) => ({
    type: PAYPAL_CHECK_SUCCESS,
    payload: payload
  });

  export const payPalCheckError = e => ({
    type: PAYPAL_CHECK_ERROR,
    error: true,
    payload: e,
  });

  export const saveTrackingRequest = () => ({
    type: SAVE_TRACKING_REQUEST,
  });

  export const saveTrackingRequestSuccess = (payload) => ({
    type: SAVE_TRACKING_REQUEST_SUCCESS,
    payload: payload
  });

  export const saveTrackingRequestError = e => ({
    type: SAVE_TRACKING_REQUEST_ERROR,
    error: true,
    payload: e,
  });

  export const savePayPalAccountInvalid = () => ({
    type: SAVE_PAYPAL_ACCOUNT_INVALID,
  });
  
  export const saveAccessTokenRequest = () => ({
    type: SAVE_ACCESS_TOKEN_REQUEST,
  });
  export const saveAccessTokenExpired = () => ({
    type: SAVE_ACCESS_TOKEN_EXPIRED,
  });
  export const saveAccessTokenRequestSuccess = (accessTokenResponse) => ({
    type: SAVE_ACCESS_TOKEN_REQUEST_SUCCESS,
    payload: accessTokenResponse,
  });
  export const saveAccessTokenRequestError = e => ({
    type: SAVE_ACCESS_TOKEN_REQUEST_ERROR,
    error: true,
    payload: e,
  });

  export const saveSignupLinkRequest = () => ({
    type: SAVE_SIGNUP_LINK_REQUEST,
  });

  export const saveSignupLinkRequestSuccess = (signupLinks) => ({
    type: SAVE_SIGNUP_LINK_REQUEST_SUCCESS,
    payload: signupLinks,
  });

  export const saveSignupLinkRequestError = e => ({
    type: SAVE_SIGNUP_LINK_REQUEST_ERROR,
    error: true,
    payload: e,
  });

  export const savePayoutDetailsRequest = () => ({
    type: SAVE_PAYOUT_DETAILS_REQUEST,
  });
  export const savePayoutDetailsSuccess = () => ({
    type: SAVE_PAYOUT_DETAILS_SUCCESS,
  });
  export const savePayoutDetailsError = e => ({
    type: SAVE_PAYOUT_DETAILS_ERROR,
    error: true,
    payload: e,
  });

export const loadData = () => (dispatch, getState, sdk) => {
    // Clear state so that previously loaded data is not visible
    // in case this page load fails.
    
    dispatch(setInitialValues());

    if(!config.payPal.baseURL) {
      console.log('error', 'PayPal API Root URL has not been configured');
    }

    // set the access token response in the state
    return dispatch(getAccessToken()).then((res) => {
      dispatch(saveAccessTokenRequestSuccess(res));      

      const currentUser = getState().user.currentUser;

      if(currentUser?.attributes?.profile?.publicData?.payPal
        && Object.keys(currentUser?.attributes?.profile?.publicData?.payPal).length != 0)
      {
        const merchantId = currentUser?.attributes?.profile?.publicData?.payPal?.merchantIdInPayPal;

        dispatch(trackSellerOnboardingStatus(merchantId)).then((res2) => {
        });
      }

      return dispatch(getPayPalSignupLink()).then((res1) => {        
      });
    });
};

export const getAccessToken = () => (dispatch, getState, sdk) => {
  dispatch(saveAccessTokenRequest());

  //curl -v POST https://api-m.sandbox.paypal.com/v1/oauth2/token \
  // -H "Accept: application/json" \
  // -H "Accept-Language: en_US" \
  //-u "CLIENT_ID:SECRET" \
  // -d "grant_type=client_credentials"
  
  return axios({
    method: 'post',
    url: 'https://' + config.payPal.baseURL + '/v1/oauth2/token',
    headers: {
      'Accept': 'application/json',
      'Accept-Language': 'en_US',
      'Content-Type': 'application/x-www-form-urlencoded',
      'PayPal-Partner-Attribution-Id': config.payPal.BNCode,
    },
    auth: {
      username: config.payPal.clientId,
      password: config.payPal.clientSecret,
    },
    params: {
      grant_type: 'client_credentials',
    },
  }).then(response => {
    let expires_in = response?.data?.expires_in;

    // get the current time in seconds (converted from milliseconds)
    let current_date_in_seconds = Math.floor(Date.now() / 1000);
    let result = response.data;
    result.expiration_date = current_date_in_seconds + expires_in;

    let res = {'accessTokenResponse': result, 'currentUser': getState()?.user?.currentUser};
    
    return res;
  })
  .catch(error => {
    dispatch(saveAccessTokenRequestError(storableError(error)));
  });
};

export const isAccessTokenValid = (expiration_date) => (dispatch, getState, sdk) => {

  const current_date_in_seconds = Math.floor(Date.now() / 1000);

  if (expiration_date > current_date_in_seconds) {
    return true;
  }

  dispatch(saveAccessTokenExpired());
  return false;
};

export const getPayPalSignupLink = () => (dispatch, getState, sdk) => {
  dispatch(saveSignupLinkRequest());

  const currentUser = getState()?.user.currentUser;

    /* curl -v -X POST https://api-m.sandbox.paypal.com/v2/customer/partner-referrals \
    -H "Content-Type: application/json" \
    -H "Authorization: Bearer <Access-Token>" \
    -d '{
      "tracking_id": "<Tracking-ID>",
      "operations": [
        {
          "operation": "API_INTEGRATION",
          "api_integration_preference": {
            "rest_api_integration": {
              "integration_method": "PAYPAL",
              "integration_type": "THIRD_PARTY",
              "third_party_details": {
                "features": [
                  "PAYMENT",
                  "REFUND"
              ]
              }
            }
          }
        }
      ],
      "products": [
        "EXPRESS_CHECKOUT"
      ],
      "legal_consents": [
        {
          "type": "SHARE_DATA_CONSENT",
          "granted": true
        }
      ]
  }' */

  const accessTokenResponse = dispatch(getAccessToken()).then((res) => {
    return res;
  });

  return axios({
    method: 'post',
    url: 'https://' + config.payPal.baseURL + '/v2/customer/partner-referrals',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getState()?.PayPalPayoutPage?.accessTokenResponse?.access_token? 
      'Bearer ' + getState()?.PayPalPayoutPage?.accessTokenResponse?.access_token 
        : 'Bearer ' + accessTokenResponse?.access_token,
      'PayPal-Partner-Attribution-Id': config?.payPal?.BNCode
    },
    data: {
      "individual_owners": [
        {
          "names": [
            {
              "given_name": currentUser?.attributes?.profile?.firstName,
              "surname": currentUser?.attributes?.profile?.lastName,
              "type": "LEGAL"
            }
          ]
        }
      ],
      "email": currentUser?.attributes?.email,
      "preferred_language_code": "en-US",
      "tracking_id": currentUser?.id?.uuid,
      "operations": [
        {
          "operation": "API_INTEGRATION",
          "api_integration_preference": {
            "rest_api_integration": {
              "integration_method": "PAYPAL",
              "integration_type": "THIRD_PARTY",
              "third_party_details": {
                "features": [
                  "PAYMENT",
                  "REFUND",
                  "DELAY_FUNDS_DISBURSEMENT",
                ]
              }
            }
          }
        }
      ],
      "partner_config_override": {
        "partner_logo_url": config?.internetRootURL + "/static/media/EMBRYOPAYPALLLOGO-01.6099e38a.jpg",
        "return_url": config?.internetRootURL + "/account/payments-paypal/onboarding-response",
        "return_url_description": "the url to return the seller after the paypal onboarding process.",
        "action_renewal_url": config?.internetRootURL + "/account/payments-paypal",
        "show_add_credit_card": true
      },
      "legal_consents": [
        {
          "type": "SHARE_DATA_CONSENT",
          "granted": true
        }
      ],
      "products": [
        "EXPRESS_CHECKOUT"
      ]
    }
  })
  .then(async response => {
    await dispatch(saveSignupLinkRequestSuccess(response.data.links));
    return response?.data?.links;
  })
  .catch(error => {
    dispatch(saveSignupLinkRequestError(storableError(error)));
  });
};

export const trackSellerOnboardingStatus = (merchantIdInPayPal) => (dispatch, getState, sdk) => {
  dispatch(payPalCheckRequest());

  const accessTokenResponse = dispatch(getAccessToken()).then((res) => {
    return res;
  });

  // curl -v -X GET 
  // https://api-m.sandbox.paypal.com/v1/customer/partners/{partner_merchant_id}/merchant-integrations/{seller_merchant_id} \
  // -H "Content-Type: application/json" \
  // -H "Authorization: Bearer <Access-Token>"

  return axios({
    method: 'get',
    url: 'https://' + config.payPal.baseURL + '/v1/customer/partners/' 
      + config.payPal.partnerMerchantId + '/merchant-integrations/' + merchantIdInPayPal,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getState()?.PayPalPayoutPage?.accessTokenResponse?.access_token? 
      'Bearer ' + getState()?.PayPalPayoutPage?.accessTokenResponse?.access_token 
        : getState()?.CheckoutPage?.accessTokenResponse?.access_token? 
        'Bearer ' + getState()?.CheckoutPage?.accessTokenResponse?.access_token
        : 'Bearer ' + accessTokenResponse?.access_token,
      'PayPal-Partner-Attribution-Id': config.payPal.BNCode,
    },
  })
  .then(response => {
      dispatch(saveTrackingRequestSuccess(response.data));
      dispatch(payPalCheckSuccess({
        isPayPalAccountConnected: response?.data?.payments_receivable &&
          response?.data?.primary_email_confirmed && response?.data?.oauth_integrations.length > 0,
        paypalVerification: {
          ...response?.data,
        }
      }));
      
      return response?.data;
    })
  .catch(error => {
    dispatch(saveTrackingRequestError(storableError(error)));
    return
  });
};

export const saveSellerPayoutDetails = (payPalDetails) => (dispatch, getState, sdk) => {
  dispatch(savePayoutDetailsRequest());

  const currentUser = getState()?.PayPalPayoutPage?.currentUser;
  const currentUserDet = ensureCurrentUser(currentUser);
  let publicData = currentUserDet?.attributes?.profile?.publicData;
  publicData = { ...publicData, payPal: payPalDetails };

  const queryParams = {
    expand: true,
  };

  return sdk.currentUser
      .updateProfile({ publicData }, queryParams)
      .then(response => {
        dispatch(savePayoutDetailsSuccess());

        const entities = denormalisedResponseEntities(response);
        if (entities.length !== 1) {
          throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
        }
        const currentUser = entities[0];        

        // Update current user in state.user.currentUser through user.duck.js
        dispatch(currentUserShowSuccess(currentUser));
      })
      .catch(e => dispatch(savePayoutDetailsError(storableError(e))));
};

export const createPayPalOrder = (orderData) => (dispatch, getState, sdk) => {
  /*     curl -v -X POST https://api-m.sandbox.paypal.com/v2/checkout/orders \
    -H "Content-Type: application/json" \
    -H "Authorization: Bearer <Access-Token>" \
    -H "PayPal-Request-Id: 7b92603e-77ed-4896-8e78-5dea2050476a" \
    -d '{
      "intent": "CAPTURE",
      "purchase_units": [
        {
          "reference_id": "d9f80740-38f0-11e8-b467-0ed5f89f718b",
          "amount": {
            "currency_code": "USD",
            "value": "100.00"
          }
        }
      ],
      "payment_source": {
        "paypal": {
          "experience_context": {
            "payment_method_preference": "IMMEDIATE_PAYMENT_REQUIRED",
            "payment_method_selected": "PAYPAL",
            "brand_name": "EXAMPLE INC",
            "locale": "en-US",
            "landing_page": "LOGIN",
            "shipping_preference": "SET_PROVIDED_ADDRESS",
            "user_action": "PAY_NOW",
            "return_url": "https://example.com/returnUrl",
            "cancel_url": "https://example.com/cancelUrl"
          }
        }
      }
    }' */

    

  loadCustomScript({
    "client-id": config.payPal.clientId,
    "attributes": {
      "data-namespace": "paypal_sdk"
    },
    "data-client-token": config.payPal.clientToken,
    "data-order-id": orderData?.id,
    "data-amount": orderData?.amount,
    "data-currency": orderData?.currency,
    "data-intent": orderData?.intent,
    "data-merchant-id": orderData?.merchant_id,
    "data-merchant-integration-id": orderData?.merchant_integration_id,
    "data-merchant-integration-type": orderData?.merchant_integration_type,

  })
};