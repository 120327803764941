import React, { useRef, useState, useEffect } from 'react';
import { bool, func, number, string } from 'prop-types';
import { Form as FinalForm, FormSpy } from 'react-final-form';

import config from '../../../config';
import { FormattedMessage, useIntl } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { numberAtLeast, required } from '../../../util/validators';
import { markets } from '../../../config/marketplace-custom-config';

import {
  Button,
  Form,
  FieldSelect,
  FieldTextInput,
  InlineTextButton,
  PrimaryButton,
  Modal,
} from '../../../components';

import EstimatedCustomerBreakdownMaybe from '../EstimatedCustomerBreakdownMaybe';

import css from './ProductOrderForm.module.css';

const renderForm = formRenderProps => {
  const {
    // FormRenderProps from final-form
    handleSubmit,
    form: formApi,

    // Custom props passed to the form component
    intl,
    formId,
    availability,
    sizes,
    listingId,
    isClosed,
    isOwnListing,
    onFetchTransactionLineItems,
    onContactUser,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    onManageDisableScrolling,
    isAuthenticated,
    accept_offer,
    values,    
    offer_price,
    changeOfferClicked,
  } = formRenderProps;

  const handleOnChange = formValues => {
    const {
      size,
      price, 
      domestic_shipping_price, 
      intl_shipping_price,
      quantity
    } = formValues.values;

    if (quantity && size && !fetchLineItemsInProgress) {
      onFetchTransactionLineItems({
        orderData: {
          availability,          
          deliveryMethod: 'shipping',
          location: 'domestic',
          domestic_shipping_price,
          intl_shipping_price,
          price,
          quantity,
          offer_price,
          size: availability === 'one_of_one'? sizes[0]: size,
          intlShippingAvailable: intl_shipping_price.amount > 0,
        },
        listingId,
        isOwnListing,
      });
    }
  };

  // In case quantity and deliveryMethod are missing focus on that select-input.
  // Otherwise continue with the default handleSubmit function.
  const handleFormSubmit = e => {
    handleSubmit(e);
  };

  const loadLineItems = () => {
    const orderData = formApi.getState().values;
    if (!fetchLineItemsInProgress) {
      onFetchTransactionLineItems({
        orderData,
        listingId,
        isOwnListing,
      });
    }
  }

  const changeSize = (e) => {
    formApi.change('size', e.target.value);
    formApi.getState().values.size === ""? setSizeError(true) : setSizeError(false);
  };

  const breakdownData = {};
  const showBreakdown =
    breakdownData && lineItems && !fetchLineItemsInProgress && !fetchLineItemsError;
  const breakdown = showBreakdown ? (
    <div className={css.breakdownWrapper}>
      <h3>
        <FormattedMessage id="ProductOrderForm.breakdownTitle" />
      </h3>
      <EstimatedCustomerBreakdownMaybe
        unitType={config.lineItemUnitType}
        breakdownData={breakdownData}
        lineItems={lineItems}
      />
    </div>
  ) : null;

  const showContactUser = typeof onContactUser === 'function';

  const onClickContactUser = e => {
    e.preventDefault();
    if(!authenticatedOnClientSide) {
      authenticatePopUp();
    } else {
      changeOfferClicked(false);
      onContactUser();
    }
  };

  const onClickOffer = e => {
    e.preventDefault();
    if(!authenticatedOnClientSide) {
      authenticatePopUp();
    } else {
      changeOfferClicked(true);
      onContactUser();
    }
  }

  const contactSellerLink = (
    <InlineTextButton onClick={onClickContactUser}>
      <FormattedMessage id="ProductOrderForm.finePrintNoStockLinkText" />
    </InlineTextButton>
  );
  const quantityRequiredMsg = intl.formatMessage({ id: 'ProductOrderForm.quantityRequired' });

  const submitInProgress = fetchLineItemsInProgress;
  const submitDisabled = isOwnListing || isClosed;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mounted, setMounted] = useState(false);
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const [sizeError, setSizeError] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const modal = (
    onManageDisableScrolling? (
      <Modal
      id="authenticateUserPopup"
      isOpen={isModalOpen}
      onClose={() => {
        setIsModalOpen(false);
      }}
      usePortal
      contentClassName={css.modalContent}
      onManageDisableScrolling={onManageDisableScrolling}
      >
        <h2 className={css.modalTitle}>
          <FormattedMessage id="AuthenticationModal.title" />
        </h2>
        <div className={css.modalDescription}>
          <p>
            <FormattedMessage id="AuthenticationModal.Description" />
          </p>
          <Button onClick={() => {
            setIsModalOpen(false);
            window.location.href = "/login";
          }}>
            <FormattedMessage id="AuthenticationModal.Button" />
          </Button>
        </div>
      </Modal>
      ) : null
  )

  const authenticatePopUp = (e) => {
    // e.preventDefault();
    !authenticatedOnClientSide? setIsModalOpen(true) : setIsModalOpen(false);
  };

  const trySubmit = (e) => {
    e.preventDefault();
    let s = formApi.getState().values.size;
    s? null : (
      s = document.getElementsByName("size")[0],
      formApi.change('size', s.value)
    )
    if (authenticatedOnClientSide && (formApi.getState().values.size != "" || availability === 'one_of_one')) {
      availability === 'one_of_one' ? (
        formApi.change('size', sizes[0]),
        loadLineItems()
        ) : null;
      handleFormSubmit(e);
    }
    authenticatePopUp();
  };

  return (
    <Form onSubmit={trySubmit}>
      <FormSpy subscription={{ values: true }} onChange={handleOnChange} />
      <div className={css.sizeForm}>
        { availability === 'one_of_one'? (
          <div className={css.sizeNoForm}>
            <span className={css.sizeValue}>{sizes}</span>
            <input id={`${formId}_size`} type="hidden" name="size" value={sizes}/>
          </div>
        ) : (
        <select
          id={`${formId}_size`}
          className={`${css.sizeField} ${sizeError? css.sizeFieldError: null}`}
          name="size"
          placeholder={intl.formatMessage({ id: 'ProductOrderForm.sizePlaceholder' })}
          onChange={changeSize}
        >
          <option value="">
            {intl.formatMessage({ id: 'ProductOrderForm.sizePlaceholder' })}
          </option>
          {sizes? sizes.map(c => (
            <option key={c} value={c}>
              {c}
            </option>
          )) : null}
        </select>)}        
      </div>
      <div className={css.sizeError}>{ sizeError? intl.formatMessage({ id: 'ProductOrderForm.sizeRequired' }) : null }</div>
        {(accept_offer[0] == "true" || accept_offer == true )? (
          <div className={css.contactSellerForm}>
            <Button
              className={`${css.contactSeller} ${css.offer}`}
              type="button"
              onClick={onClickOffer}
              disabled={submitDisabled}
            >
              {intl.formatMessage({ id: 'ProductOrderForm.sendAOffer' })}
            </Button>

            <Button
              className={`${css.contactSeller} ${css.message}`}
              type="button"
              onClick={onClickContactUser}
              disabled={submitDisabled}
            >
              {intl.formatMessage({ id: 'ProductOrderForm.sendSellerAMessage' })}
            </Button>
          </div>
        ) : null
        }
      <div className={`${css.submitButton} ${(accept_offer == true || accept_offer[0] == "true")? css.null: css.contactSellerForm}`}>
        <PrimaryButton 
          className={`${css.purchase} ${(accept_offer == true || accept_offer[0] == "true")? css.null: css.contactSeller}`}
          type="submit"
          inProgress={submitInProgress}
          disabled={submitDisabled}
        >
          {!isOwnListing ? (
            <FormattedMessage id="ProductOrderForm.ctaButton" />
          ) : (
            <FormattedMessage id="ProductOrderForm.ctaButton" />
          )}
        </PrimaryButton>
        { (accept_offer == true || accept_offer[0] == "true")? null: (
            <Button
              className={`${css.contactSeller} ${css.message}`}
              type="button"
              onClick={onClickContactUser}
              disabled={submitDisabled}
            >
              {intl.formatMessage({ id: 'ProductOrderForm.sendSellerAMessage' })}
            </Button>
          )
        }
      </div>
      {modal}
    </Form>
  );
};

const ProductOrderForm = props => {
  const intl = useIntl();
  const { price, 
          domestic_shipping_price, 
          intl_shipping_price,
          availability,
          offer_price,
         } = props;
  // Should not happen for listings that go through EditListingWizard.
  // However, this might happen for imported listings.

  const initialValues = {
    price,
    size: '',
    availability,
    offer_price,
    deliveryMethod: 'shipping',
    location: 'domestic',
    domestic_shipping_price,
    intl_shipping_price,
    quantity: Number.parseInt(1, 10),
    intlShippingAvailable: (intl_shipping_price.amount > 0),
  };

  if (!domestic_shipping_price && !intl_shipping_price) {
    <p className={css.error}>
      <FormattedMessage id="ProductOrderForm.noDeliveryMethodSet" />
    </p>;
  }

  if (!price) {
    return (
      <p className={css.error}>
        <FormattedMessage id="ProductOrderForm.listingPriceMissing" />
      </p>
    );
  }
  if (price.currency !== config.currency) {
    return (
      <p className={css.error}>
        <FormattedMessage id="ProductOrderForm.listingCurrencyInvalid" />
      </p>
    );
  }

  const hasMultipleDeliveryMethods = domestic_shipping_price && intl_shipping_price;

  return (
    <FinalForm
      initialValues={initialValues}
      hasMultipleDeliveryMethods={hasMultipleDeliveryMethods}
      {...props}
      intl={intl}
      render={renderForm}
    />
  );
};

ProductOrderForm.defaultProps = {
  rootClassName: null,
  className: null,
  price: null,
  domestic_shipping_price: null,
  intl_shipping_price: null,
  listingId: null,
  isOwnListing: false,
  lineItems: null,
  fetchLineItemsError: null,
};

ProductOrderForm.propTypes = {
  rootClassName: string,
  className: string,

  // form
  formId: string.isRequired,
  onSubmit: func.isRequired,

  // listing
  listingId: propTypes.uuid,
  price: propTypes.money,
  /* currentStock: number, */
  isClosed: bool,
  isOwnListing: bool,

  // line items
  lineItems: propTypes.lineItems,
  onFetchTransactionLineItems: func.isRequired,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // other
  onContactUser: func,
  changeOfferClicked: func,
};

export default ProductOrderForm;
