import pick from 'lodash/pick';
import {
  createStripeAccount,
  updateStripeAccount,
  fetchStripeAccount,
} from '../../ducks/paypalConnectAccount.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import paypal from '../../config/paypal-config';
import config from '../../config';
import { initial } from 'lodash';

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/PayPalSellerOnboardingPage/SET_INITIAL_VALUES';
export const SAVE_PAYOUT_DETAILS_REQUEST = 'app/PayPalSellerOnboardingPage/SAVE_PAYOUT_DETAILS_REQUEST';
export const SAVE_PAYOUT_DETAILS_SUCCESS = 'app/PayPalSellerOnboardingPage/SAVE_PAYOUT_DETAILS_SUCCESS';
export const SAVE_PAYOUT_DETAILS_ERROR = 'app/PayPalSellerOnboardingPage/SAVE_PAYOUT_DETAILS_ERROR';

const axios = require('axios');
const request = require('request');

// ================ Reducer ================ //

const initialState = {
  payoutDetailsSaveInProgress: false,
  payoutDetailsSaved: false,
  fromReturnURL: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SAVE_PAYOUT_DETAILS_REQUEST:
      return { ...state, payoutDetailsSaveInProgress: true };
    case SAVE_PAYOUT_DETAILS_ERROR:
      return { ...state, payoutDetailsSaveInProgress: false };
    case SAVE_PAYOUT_DETAILS_SUCCESS:
      return { ...state, payoutDetailsSaveInProgress: false, payoutDetailsSaved: true };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const savePayoutDetailsRequest = () => ({
  type: SAVE_PAYOUT_DETAILS_REQUEST,
});
export const savePayoutDetailsError = () => ({
  type: SAVE_PAYOUT_DETAILS_ERROR,
});
export const savePayoutDetailsSuccess = () => ({
  type: SAVE_PAYOUT_DETAILS_SUCCESS,
});

// ================ Thunks ================ //

export const savePayoutDetails = (values, isUpdateCall) => (dispatch, getState, sdk) => {
  const upsertThunk = isUpdateCall ? updateStripeAccount : createStripeAccount;
  dispatch(savePayoutDetailsRequest());

  return dispatch(upsertThunk(values, { expand: true }))
    .then(response => {
      dispatch(savePayoutDetailsSuccess());
      return response;
    })
    .catch(() => dispatch(savePayoutDetailsError()));
};

export const loadData = () => (dispatch, getState, sdk) => {
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialValues());

  return dispatch(fetchCurrentUser()).then(response => {
    const currentUser = getState().user.currentUser;
    if (currentUser && currentUser.stripeAccount) {
      dispatch(fetchStripeAccount());
    }
    return response;
  });
};

function getAccessTokenFromPayPal() {
  return (async () => {
    try {
      const { data: { access_token } } = await axios({
      method: 'POST',
      url: `${paypal.SandboxBaseURL}v1/oauth2/token`,
      headers: {
        'Accept': 'application/json',
        'Accept-Language': 'en_US',
        'content-type': 'application/x-www-form-urlencoded',
      },
      auth: {
        'username': paypal.ClientId,
        'password': paypal.ClientSecret,
      },
      params: {
        'grant_type': 'client_credentials',
      }
    });

      return access_token;
    } catch (error) {
      console.log('error', error);
    }
  })();
}

export const getTheSignupLinkFromPayPal = (tracking_id, successURL, failureURL) => (dispatch, getState, sdk) => {
  initialState.payoutDetailsSaveInProgress = true;
  let accessToken = '';
  return new Promise((resolve, reject) => {
    resolve(getAccessTokenFromPayPal())
  }).then(response => {
    accessToken = response;
    return new Promise((resolve, reject) => {
      request.post({
        uri: `${paypal.SandboxBaseURL}v2/customer/partner-referrals`,
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${accessToken}`,
        },
        raw: {
            "tracking_id": tracking_id,
            "partner_config_override": {
              "return_url": successURL,
              "renew_action_url": failureURL,
            },
            "money": [
              {
                "currency_code": config.currency,
              }
            ],
            "operations": [
              {
                "operation": "API_INTEGRATION",
                "api_integration_preference": {
                  "rest_api_integration": {
                    "integration_method": "PAYPAL",
                    "integration_type": "THIRD_PARTY",
                    "third_party_details": {
                      "features": [
                        "PAYMENT",
                        "REFUND",
                        "DELAY_FUNDS_DISBURSEMENT",
                     ]
                    }
                  }
                }
              }
            ],
            "products": [
              "EXPRESS_CHECKOUT"
            ],
            "legal_consents": [
              {
                "type": "SHARE_DATA_CONSENT",
                "granted": true
              }
            ]
        }
      }, function (error, response, body) {
        initialState.payoutDetailsSaveInProgress = false;
        if(!error)
        {
          return resolve(JSON.parse(body));
        } else {
          throw error;
        }
      });
    })
    .then(response => {
      return response;
    })
  }).catch(error => {
    initialState.payoutDetailsSaveInProgress = false;
    throw error;
  });
}

export const trackSellerPayPalAccountStatus = (tracking_id, tracking_url) => (dispatch, getState, sdk) => {
  console.log('trackSellerPayPalAccountStatus', tracking_id, tracking_url);
  let accessToken = ''
  return new Promise((resolve, reject) => {
    resolve(getAccessTokenFromPayPal())
  }).then(response => {
    accessToken = response;
    return new Promise((resolve, reject) => {
      request.get({
        uri: `${paypal.SandboxBaseURL}v1/customer/partners/partner_id/merchant-integrations?tracking_id=${tracking_id}`,
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${accessToken}`,
        }
      }, function (error, response, body) {
        if(!error)
        {
          const partner_referral_id = JSON.parse(body);
          console.log('partner_referral_id', partner_referral_id);

          return new Promise((resolve, reject) => {
            request.get({
              uri: `${paypal.SandboxBaseURL}v1/customer/partners/${paypal.SandBoxMerchantID}/merchant-integrations/${tracking_id}`,
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${accessToken}`,
              },
            }, function (error, response, body) {
              if(!error)
              {
                return resolve(JSON.parse(body));
              } else {
                throw error;
              }
            })
          }).then(response => {
            return response;
          })
          .catch(error => {
            throw error;
          });
        } else {
          throw error;
        }
      });
    }
    ).then(response => {
      return response;
    }
    ).catch(error => {
      throw error;
    }
    );
  }).catch(error => {
    throw error;
  }
  );
}