import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, } from '../../util/reactIntl';
import LogoImageMobile from './EMBRYOLOGOwhiteMOBILE.png';
import LogoImage from './EMBRYOLOGOwhiteDESKTOP.png';
const Logo = ({ className, format, ...rest }) => {
if (format === 'desktop') {
return <img className={className} src={LogoImage} alt="EMBRYO"
{...rest} />;
}
return <img className={className} src={LogoImageMobile} alt="EMBRYO"
{...rest} />;
};
Logo.defaultProps = {
className: null,
format: 'desktop',
};
Logo.propTypes = {
className: PropTypes.string,
format: PropTypes.oneOf(['desktop', 'mobile']),
};
export default Logo;
