import { capitalize } from "lodash";

export const markets = [
  // This is the default categorization or structure of all Embryo marketplace listings.
  // This is used to create the default filters and to categorize listings.

  // availability is available to all excepts Art market.
  // Sizes are scoped to the category level.
  {key: 'womens', label: 'WOMENS', availability: [
    { key: 'one_of_one', label: 'ONE OF ONE' },
    { key: 'made_to_order', label: 'MADE TO ORDER' },
  ], categories: [
    { key: 'accessories', label: 'ACCESSORIES', subcategories: [
      { key: 'belts', label: 'Belts', size: [24, 26, 28, 30, 32, 34, 36, 38, 40, 42, 44] },
      { key: 'bracelets', label: 'Bracelets', size: [4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5] },
      { key: 'earrings', label: 'Earrings', size: ['ONE SIZE'] },
      { key: 'eyewear', label: 'Eyewear', size: ['ONE SIZE'] },
      { key: 'gloves', label: 'Gloves', size: ['ONE SIZE'] },
      { key: 'hats', label: 'Hats', size: ['ONE SIZE'] },
      { key: 'necklaces', label: 'Necklaces', size: [14, 16, 18, 20, 22, 24, 26, 28, 30] },
      { key: 'masks', label: 'Masks', size: ['ONE SIZE'] },
      { key: 'misc', label: 'Misc', size: ['ONE SIZE'] },
      { key: 'rings', label: 'Rings', size: [6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13] },
      { key: 'scarves', label: 'Scarves', size: ['ONE SIZE'] },
    ]},
    { key: 'bags', label: 'BAGS', subcategories: [
      { key: 'backpacks', label: 'Backpacks', size: ['ONE SIZE'] },
      { key: 'clutches_pouches', label: 'Clutches & Pouches', size: ['ONE SIZE'] },
      { key: 'shoulderbags', label: 'Shoulder', size: ['ONE SIZE'] },
      { key: 'dufflebags', label: 'Duffle Bags', size: ['ONE SIZE'] },
      { key: 'totebags', label: 'Tote Bags', size: ['ONE SIZE'] },
    ]},
    { key: 'clothing', label: 'CLOTHING', subcategories: [
      { key: 'activewear', label: 'Activewear', size: ['2XS','XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', 'ONE SIZE'] },
      { key: 'bottoms', label: 'Bottoms', size: [24,26,28,30,32,34,36,38,40,42,44]},
      { key: 'denim', label: 'Denim', size: [24,26,28,30,32,34,36,38,40,42,44] },
      { key: 'dresses', label: 'Dresses', size: ['2XS', 'XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', 'ONE SIZE'] },
      { key: 'jackets', label: 'Jackets', size: ['2XS','XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', 'ONE SIZE'] },
      { key: 'knitwear', label: 'Knitwear', size: ['2XS','XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', 'ONE SIZE'] },
      { key: 'outerwear', label: 'Outerwear', size: ['2XS','XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', 'ONE SIZE'] },
      { key: 'tops', label: 'Tops', size: ['2XS','XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', 'ONE SIZE'] },
    ]},
    { key: 'footwear', label: 'FOOTWEAR', subcategories: [
      { key: 'boots', label: 'Boots', size: [4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 14, 15] },
      { key: 'flats', label: 'Flats', size: [4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 14, 15] },
      { key: 'heels', label: 'Heels', size: [4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 14, 15] },
      { key: 'sandals', label: 'Sandals', size: [4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 14, 15] },
      { key: 'sneakers', label: 'Sneakers', size: [4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 14, 15] },
    ]},
  ]},
  {key: 'neutral', label: 'NEUTRAL', availability: [
    { key: 'one_of_one', label: 'ONE OF ONE' },
    { key: 'made_to_order', label: 'MADE TO ORDER' },
  ], categories: [
    { key: 'accessories', label: 'ACCESSORIES', subcategories: [
      { key: 'belts', label: 'Belts', size: [24, 26, 28, 30, 32, 34, 36, 38, 40, 42, 44] },
      { key: 'bracelets', label: 'Bracelets', size: [4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5] },
      { key: 'earrings', label: 'Earrings', size: ['ONE SIZE'] },
      { key: 'eyewear', label: 'Eyewear', size: ['ONE SIZE'] },
      { key: 'gloves', label: 'Gloves', size: ['ONE SIZE'] },
      { key: 'hats', label: 'Hats', size: ['ONE SIZE'] },
      { key: 'necklaces', label: 'Necklaces', size: [14, 16, 18, 20, 22, 24, 26, 28, 30] },
      { key: 'masks', label: 'Masks', size: ['ONE SIZE'] },
      { key: 'misc', label: 'Misc', size: ['ONE SIZE'] },
      { key: 'rings', label: 'Rings', size: [6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13] },
      { key: 'scarves', label: 'Scarves', size: ['ONE SIZE'] },
    ]},
    { key: 'bags', label: 'BAGS', subcategories: [
      { key: 'backpacks', label: 'Backpacks', size: ['ONE SIZE'] },
      { key: 'clutches_pouches', label: 'Clutches & Pouches', size: ['ONE SIZE'] },
      { key: 'shoulderbags', label: 'Shoulder Bags', size: ['ONE SIZE'] },
      { key: 'dufflebags', label: 'Duffle Bags', size: ['ONE SIZE'] },
      { key: 'totebags', label: 'Tote Bags', size: ['ONE SIZE'] },
    ]},
    { key: 'clothing', label: 'CLOTHING', subcategories: [
      { key: 'activewear', label: 'Activewear', size: ['2XS','XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', 'ONE SIZE'] },
      { key: 'bottoms', label: 'Bottoms', size: [24,26,28,30,32,34,36,38,40,42,44]},
      { key: 'denim', label: 'Denim', size: [24,26,28,30,32,34,36,38,40,42,44] },
      { key: 'dresses', label: 'Dresses', size: ['2XS','XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', 'ONE SIZE'] },
      { key: 'jackets', label: 'Jackets', size: ['2XS','XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', 'ONE SIZE'] },
      { key: 'knitwear', label: 'Knitwear', size: ['2XS','XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', 'ONE SIZE'] },
      { key: 'outerwear', label: 'Outerwear', size: ['2XS','XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', 'ONE SIZE'] },
      { key: 'tops', label: 'Tops', size: ['2XS','XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', 'ONE SIZE'] },
    ]},
    { key: 'footwear', label: 'FOOTWEAR', subcategories: [
      { key: 'boots', label: 'Boots', size: [4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 14, 15] },
      { key: 'flats', label: 'Flats', size: [4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 14, 15] },
      { key: 'formal', label: 'Formal', size: [4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 14, 15] },
      { key: 'heels', label: 'Heels', size: [4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 14, 15] },
      { key: 'sandals', label: 'Sandals', size: [4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 14, 15] },
      { key: 'sneakers', label: 'Sneakers', size: [4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 14, 15] },
    ]},
  ]},
  {key: 'mens', label: 'MENS', availability: [
    { key: 'one_of_one', label: 'ONE OF ONE' },
    { key: 'made_to_order', label: 'MADE TO ORDER' },
  ], categories: [
    { key: 'accessories', label: 'ACCESSORIES', subcategories: [
      { key: 'belts', label: 'Belts', size: [24, 26, 28, 30, 32, 34, 36, 38, 40, 42, 44] },
      { key: 'bracelets', label: 'Bracelets', size: [4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5] },
      { key: 'earrings', label: 'Earrings', size: ['ONE SIZE'] },
      { key: 'eyewear', label: 'Eyewear', size: ['ONE SIZE'] },
      { key: 'gloves', label: 'Gloves', size: ['ONE SIZE'] },
      { key: 'hats', label: 'Hats', size: ['ONE SIZE'] },
      { key: 'necklaces', label: 'Necklaces', size: [14, 16, 18, 20, 22, 24, 26, 28, 30] },      ,
      { key: 'masks', label: 'Masks', size: ['ONE SIZE'] },
      { key: 'misc', label: 'Misc', size: ['ONE SIZE'] },
      { key: 'rings', label: 'Rings', size: [6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13] },
      { key: 'scarves', label: 'Scarves', size: ['ONE SIZE'] },
    ]},
    { key: 'bags', label: 'BAGS', subcategories: [
      { key: 'backpacks', label: 'Backpacks', size: ['ONE SIZE'] },
      { key: 'clutches_pouches', label: 'Clutches & Pouches', size: ['ONE SIZE'] },
      { key: 'shoulderbags', label: 'Shoulder Bags', size: ['ONE SIZE'] },
      { key: 'dufflebags', label: 'Duffle Bags', size: ['ONE SIZE'] },
      { key: 'totebags', label: 'Tote Bags', size: ['ONE SIZE'] },
    ]},
    { key: 'clothing', label: 'CLOTHING', subcategories: [
      { key: 'activewear', label: 'Activewear', size: ['2XS','XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', 'ONE SIZE'] },
      { key: 'bottoms', label: 'Bottoms', size: [24,26,28,30,32,34,36,38,40,42,44] },
      { key: 'denim', label: 'Denim', size: [24,26,28,30,32,34,36,38,40,42,44] },
      { key: 'jackets', label: 'Jackets', size: ['2XS','XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', 'ONE SIZE'] },
      { key: 'knitwear', label: 'Knitwear', size: ['2XS','XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', 'ONE SIZE'] },
      { key: 'outerwear', label: 'Outerwear', size: ['2XS','XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', 'ONE SIZE'] },
      { key: 'tops', label: 'Tops', size: ['2XS','XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', 'ONE SIZE'] },
    ]},
    { key: 'footwear', label: 'FOOTWEAR', subcategories: [
      { key: 'boots', label: 'Boots', size: [4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 14, 15] },
      { key: 'formal', label: 'Formal', size: [4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 14, 15] },
      { key: 'sandals', label: 'Sandals', size: [4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 14, 15] },
      { key: 'sneakers', label: 'Sneakers', size: [4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 14, 15] },
    ]},
  ]},
  {key: 'art', label: 'ART', availability: [
    { key: 'one_of_one', label: 'ONE OF ONE' },
    { key: 'made_to_order', label: 'MADE TO ORDER' },
  ], categories: [
    { key: 'ceramic', label: 'CERAMIC', subcategories: [
      { size: ['ONE SIZE'] },
    ] },
    { key: 'furniture', label: 'FURNITURE', subcategories: [
      { size: ['ONE SIZE'] },
    ] },
    { key: 'lighting', label: 'LIGHTING', subcategories: [
      { size: ['ONE SIZE'] },
    ] },
    { key: 'painting', label: 'PAINTING', subcategories: [
      { size: ['ONE SIZE'] },
    ] },
    { key: 'sculpture', label: 'SCULPTURE', subcategories: [
      { size: ['ONE SIZE'] },
    ] },
    { key: 'textiles', label: 'TEXTILES', subcategories: [
      { size: ['ONE SIZE'] },
    ] },
    { key: 'toys', label: 'TOYS', subcategories: [
      { size: ['ONE SIZE'] },
    ] },
  ]}
];

export function filterGenerator(filterName, filterOptions) {

  let filterArray = {
    id: filterName,
    config: {schemaType: 'enum', options: filterOptions},
    group: "primary",
    label: filterName.toUpperCase(),
    queryParamNames: [`pub_${filterName}`],
    type: "SelectSingleFilter",
  }

  //OriginalFilter.push(filterArray);

  return filterArray;
}


/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

export const searchFilters = [
  {
    id: 'category',
    label: 'ALL CATEGORIES',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_category'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'accessories', label: 'ACCESSORIES' },
        { key: 'bags', label: 'BAGS' },
        { key: 'clothing', label: 'CLOTHING' },
        { key: 'footwear', label: 'FOOTWEAR' },
      ],
    },
  },

  {
    id: 'market',
    label: 'MARKET',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_market'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple values when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: markets,
    },
  },
];

export const homepageFilters = [
  {
    id: 'category',
    label: 'ALL CATEGORIES',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_category'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'accessories', label: 'ACCESSORIES' },
        { key: 'bags', label: 'BAGS' },
        { key: 'clothing', label: 'CLOTHING' },
        { key: 'footwear', label: 'FOOTWEAR' },
      ],
    },
  },
];

export const artPageFilters = [
  {
    id: 'category',
    label: 'ALL CATEGORIES',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_category'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: markets[3].categories,
    },
  },
];

export const marketFilters = [
  {
    id: 'category',
    label: 'ALL CATEGORIES',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_category'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'accessories', label: 'ACCESSORIES' },
        { key: 'bags', label: 'BAGS' },
        { key: 'clothing', label: 'CLOTHING' },
        { key: 'footwear', label: 'FOOTWEAR' },
      ],
    },
  },
  {
    id: 'size',
    label: 'SIZE(S)',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_size'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [],
    },
  },
];

export const filters = [
  {
    id: 'category',
    label: 'ALL CATEGORIES',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_category'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'accessories', label: 'ACCESSORIES' },
        { key: 'bags', label: 'BAGS' },
        { key: 'clothing', label: 'CLOTHING' },
        { key: 'footwear', label: 'FOOTWEAR' },
      ],
    },
  },

  // Here is an example of multi-enum search filter.
  //
  // {
  //   id: 'amenities',
  //   label: 'Amenities',
  //   type: 'SelectMultipleFilter',
  //   group: 'secondary',
  //   queryParamNames: ['pub_amenities'],
  //   config: {
  //     // Schema type options: 'enum', 'multi-enum'
  //     // Both types can work so that user selects multiple values when filtering search results.
  //     // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
  //     // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
  //     schemaType: 'multi-enum',

  //     // Optional modes: 'has_all', 'has_any'
  //     // Note: this is relevant only for schema type 'multi-enum'
  //     // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
  //     searchMode: 'has_all',

  //     // "key" is the option you see in Flex Console.
  //     // "label" is set here for this web app's UI only.
  //     // Note: label is not added through the translation files
  //     // to make filter customizations a bit easier.
  //     options: [
  //       { key: 'towels', label: 'Towels' },
  //       { key: 'bathroom', label: 'Bathroom' },
  //       { key: 'swimming_pool', label: 'Swimming pool' },
  //       { key: 'barbeque', label: 'Barbeque' },
  //     ],
  //   },
  // },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Relevance key is used with keywords filter.
  // Keywords filter also sorts results according to relevance.
  relevanceFilter: 'keywords',

  // Keyword filter is sorting the results by relevance.
  // If keyword filter is active, one might want to disable other sorting options
  // by adding 'keyword' to this list.
  conflictingFilters: [],

  options: [
    { key: 'createdAt', label: 'RECENT ADDITIONS' },
    { key: '-createdAt', label: ' ACTIVITY' },
    { key: '-price', label: 'PRICE: LOW TO HIGH' },
    { key: 'price', label: 'PRICE: HIGH TO LOW' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'RELEVANCE', longLabel: 'RELEVANCE (KEYWORD SEARCH)' },
  ],
};

export const listing = {
  // These should be listing details from public data with schema type: enum
  // SectionDetailsMaybe component shows these on listing page.
  enumFieldDetails: ['size', 'category'],
};

export const ships_in = [
  { key: '1-2days', label: '1-2 DAYS' },
  { key: '3-6days', label: '3-6 DAYS' },
  { key: '1-2weeks', label: '1-2WEEKS' },
  { key: '2-3weeks', label: '2-3WEEKS' },
  { key: '3-4weeks', label: '3-4WEEKS' },
  { key: '4-5weeks', label: '4-5WEEKS' },
  { key: '5-6weeks', label: '5-6WEEKS' },
]

