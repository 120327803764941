import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { arrayOf, array, bool, func, node, oneOfType, shape, string } from 'prop-types';
import { types as sdkTypes } from '../../util/sdkLoader';
import classNames from 'classnames';
import omit from 'lodash/omit';

import config from '../../config';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import {
  propTypes,
  LISTING_STATE_CLOSED,
  LINE_ITEM_NIGHT,
  LINE_ITEM_DAY,
  LINE_ITEM_UNITS,
} from '../../util/types';
import { formatMoney } from '../../util/currency';
import { parse, stringify } from '../../util/urlHelpers';
import { userDisplayNameAsString } from '../../util/data';
import { ModalInMobile, Button, AvatarSmall } from '../../components';

import BookingDatesForm from './BookingDatesForm/BookingDatesForm';
import ProductOrderForm from './ProductOrderForm/ProductOrderForm';
import css from './OrderPanel.module.css';

import { markets, ships_in } from '../../config/marketplace-custom-config';
import { checkOfferForListing } from '../../util/offers';

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const openOrderModal = (isOwnListing, isClosed, history, location) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search), orderOpen: true })}`;
    history.push(`${pathname}${searchString}`, state);
  }
};

const closeOrderModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'orderOpen');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const OrderPanel = props => {
  const {
    rootClassName,
    className,
    titleClassName,
    listing,
    isOwnListing,
    changeOfferClicked,
    unitType,
    onSubmit,
    title,
    author,
    onManageDisableScrolling,
    isAuthenticated,
    currentUser,
    timeSlots,
    fetchTimeSlotsError,
    history,
    location,
    intl,
    onFetchTransactionLineItems,
    onContactUser,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
  } = props;

  const { Money } = sdkTypes;

  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;
  const isUnits = unitType === LINE_ITEM_UNITS;
  const shouldHaveBooking = isNightly || isDaily;

  let price = listing.attributes.price;
  let offer_price = null;

  if (isAuthenticated) {
    const tempPrice = checkOfferForListing(listing.id.uuid, author, currentUser);

    if (tempPrice) {
      offer_price = new Money(
        JSON.parse(
          String(tempPrice)
            .replace(/\$/g, '')
            .replace(/\./g, '')
        ),
        config.currency
      );
    }
  }

  const hasListingState = !!listing.attributes.state;
  const isClosed = hasListingState && listing.attributes.state === LISTING_STATE_CLOSED;
  /* const showBookingDatesForm = shouldHaveBooking && hasListingState && !isClosed; */
  const showClosedListingHelpText = listing.id && isClosed;
  const { formattedPrice, priceTitle } = priceData(offer_price ? offer_price : price, intl);
  const isOrderOpen = !!parse(location.search).orderOpen;

  // The listing resource has a relationship: `currentStock`,
  // which you should include when making API calls.
  /* const currentStock = listing.currentStock?.attributes?.quantity;
  const isOutOfStock = config.listingManagementType === 'stock' && currentStock === 0; */

  // Show form only when stock is fully loaded. This avoids "Out of stock" UI by
  // default before all data has been downloaded.
  /* const showProductOrderForm =
    config.listingManagementType === 'stock' && typeof currentStock === 'number'; */

  const { pickupEnabled, shippingEnabled } = listing?.attributes?.publicData || {};

  const shipsinText = listing?.attributes?.publicData?.ships_in
    ? ships_in.map(ship => {
        if (ship.key === listing?.attributes?.publicData?.ships_in) {
          return ship.label;
        }
      })
    : null;

  const getMarketIndex = () => {
    try {
      for (let i = 0; i < markets.length; i++) {
        if (markets[i].key == listing.attributes.publicData.market) {
          return i;
        }
      }
    } catch (error) {}
  };

  const marketIndex = getMarketIndex();

  const availabilityText = listing?.attributes?.publicData?.availability
    ? markets[marketIndex]?.availability?.map(a => {
        if (a.key === listing?.attributes?.publicData?.availability) {
          return a.label;
        }
      })
    : null;

    const dsp = listing?.attributes?.publicData?.domestic_shipping_price;

    const domestic_shipping_price = (dsp != 'null')
      ? new Money(JSON.parse(listing?.attributes?.publicData?.domestic_shipping_price).amount, config.currency)
      : null;
  
    const domestic_shipping_fee = domestic_shipping_price
      ? formatMoney(intl, domestic_shipping_price)
      : null;
  
    const isp = listing?.attributes?.publicData?.intl_shipping_price;
  
    let int_s_p = listing?.attributes?.publicData?.intl_shipping_price;
    let intl_shipping_price, intl_shipping_fee = null;
  
    if (int_s_p != 'null' && int_s_p != null) {
      if (JSON.parse(int_s_p).amount != 0) {
        intl_shipping_price = new Money(JSON.parse(int_s_p).amount, config.currency);
        intl_shipping_fee = formatMoney(intl, intl_shipping_price);
      }
      else {
        intl_shipping_price = new Money(JSON.parse(int_s_p).amount, config.currency);
        intl_shipping_fee = null;
      }
    }
    else {
      intl_shipping_price = new Money(JSON.parse(int_s_p).amount, config.currency);
      intl_shipping_fee = null;
    }

  const unitTranslationKey = isNightly
    ? 'OrderPanel.perNight'
    : isDaily
    ? 'OrderPanel.perDay'
    : 'OrderPanel.perUnit';

  let accept_offer = listing?.attributes?.publicData?.accept_offer || false;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div>
        <div className={css.padding}>
        <div className={`${css.orderHeading}`}>
          <span>
            <h3 className={`${css.price} ${css.orderHeadingText}`}>{formatMoney(intl, offer_price? offer_price : price) + " USD"}</h3>
            { domestic_shipping_price? 
            (<p className={css.ships_in}>{"DOMESTIC SHIPPING: " + domestic_shipping_fee}</p>)
             : null }
          </span>
          <span className={css.productOrderavailability}>
           { availabilityText? (<h3 className={`${css.availability} ${css.orderHeadingText}`}>{availabilityText}</h3>) : null }
            { ships_in? (<p className={css.ships_in}>{"SHIPS IN " + String(shipsinText).replace(/,/g,"")}</p>) : null }
          </span>
          {/* {!showClosedListingHelpText ? <div className={css.orderHelp}>"offer expires in 24 hours"</div> : null} */}
        </div>
        <div className={css.orderBody}>          
          { intl_shipping_fee?
            (<p className={css.orderBodyText}>{"INTERNATIONAL SHIPPING: " + intl_shipping_fee}</p>)
            : null }
        </div>


          <ProductOrderForm
            formId="OrderPanelProductOrderForm"
            onSubmit={onSubmit}
            price={price}
            offer_price={offer_price}
            accept_offer={accept_offer}
            domestic_shipping_price={domestic_shipping_price}
            intl_shipping_price={intl_shipping_price}
            sizes={listing.attributes.publicData.size}
            availability={listing.attributes.publicData.availability}
            market={listing.attributes.publicData.market}
            changeOfferClicked={changeOfferClicked}
            markets={markets}
            subcategory={listing.attributes.publicData.subcategory}
            listingId={listing.id}
            isClosed={isClosed}
            isOwnListing={isOwnListing}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            onContactUser={onContactUser}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
            onManageDisableScrolling={onManageDisableScrolling}
            isAuthenticated={isAuthenticated}
          />
        </div>
      </div>
    </div>
  );
};

OrderPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  subTitle: null,
  unitType: config.lineItemUnitType,
  timeSlots: null,
  fetchTimeSlotsError: null,
  lineItems: null,
  fetchLineItemsError: null,
};

OrderPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  isOwnListing: bool,
  unitType: propTypes.lineItemUnitType,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  subTitle: oneOfType([node, string]),
  onManageDisableScrolling: func.isRequired,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  onFetchTransactionLineItems: func.isRequired,
  onContactUser: func,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(
  withRouter,
  injectIntl
)(OrderPanel);