import config from '../../config';
import { initiatePrivileged, transitionPrivileged } from '../../util/api';
import { transactionLineItems } from '../../util/api';
import { updateUsedCustomerOffer } from '../../util/offers';
import { formatMoney } from '../../util/currency';
import { denormalisedResponseEntities, ensureListing, ensureUser } from '../../util/data';
import { storableError } from '../../util/errors';
import {
  TRANSITION_REQUEST_PAYMENT,
  TRANSITION_REQUEST_PAYMENT_PAYPAL,
  TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
  TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY_PAYPAL,
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_CONFIRM_PAYMENT_PAYPAL,
  isPrivileged,
} from '../../util/transaction';
import * as log from '../../util/log';
import { fetchCurrentUserHasOrdersSuccess, fetchCurrentUser } from '../../ducks/user.duck';

import { getAccessToken, saveAccessTokenRequestSuccess, SAVE_ACCESS_TOKEN_REQUEST_SUCCESS, trackSellerOnboardingStatus, getAccessTokenData } from '../PayPalPayoutPage/PayPalPayoutPage.duck';
import axios from 'axios';
import { pick, pickBy } from 'lodash';
import { SEND_MESSAGE_ERROR } from '../TransactionPage/TransactionPage.duck';

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/CheckoutPage/SET_INITIAL_VALUES';

export const INITIATE_ORDER_REQUEST = 'app/CheckoutPage/INITIATE_ORDER_REQUEST';
export const INITIATE_ORDER_SUCCESS = 'app/CheckoutPage/INITIATE_ORDER_SUCCESS';
export const INITIATE_ORDER_ERROR = 'app/CheckoutPage/INITIATE_ORDER_ERROR';

export const INITIATE_TRANSACTION_FOR_PAYMENT_REQUEST = 'app/ListingPage/INITIATE_TRANSACTION_FOR_PAYMENT_REQUEST';
export const INITIATE_TRANSACTION_FOR_PAYMENT_SUCCESS = 'app/ListingPage/INITIATE_TRANSACTION_FOR_PAYMENT_SUCCESS';
export const INITIATE_TRANSACTION_FOR_PAYMENT_ERROR = 'app/ListingPage/INITIATE_TRANSACTION_FOR_PAYMENT_ERROR';

export const CONFIRM_PAYMENT_REQUEST = 'app/CheckoutPage/CONFIRM_PAYMENT_REQUEST';
export const CONFIRM_PAYMENT_SUCCESS = 'app/CheckoutPage/CONFIRM_PAYMENT_SUCCESS';
export const CONFIRM_PAYMENT_ERROR = 'app/CheckoutPage/CONFIRM_PAYMENT_ERROR';

export const CONFIRM_PAYPAL_PAYMENT_REQUEST = 'app/CheckoutPage/CONFIRM_PAYPAL_PAYMENT_REQUEST';
export const CONFIRM_PAYPAL_PAYMENT_SUCCESS = 'app/CheckoutPage/CONFIRM_PAYPAL_PAYMENT_SUCCESS';
export const CONFIRM_PAYPAL_PAYMENT_ERROR = 'app/CheckoutPage/CONFIRM_PAYPAL_PAYMENT_ERROR';

export const SPECULATE_TRANSACTION_REQUEST = 'app/ListingPage/SPECULATE_TRANSACTION_REQUEST';
export const SPECULATE_TRANSACTION_SUCCESS = 'app/ListingPage/SPECULATE_TRANSACTION_SUCCESS';
export const SPECULATE_TRANSACTION_ERROR = 'app/ListingPage/SPECULATE_TRANSACTION_ERROR';

export const STRIPE_CUSTOMER_REQUEST = 'app/CheckoutPage/STRIPE_CUSTOMER_REQUEST';
export const STRIPE_CUSTOMER_SUCCESS = 'app/CheckoutPage/STRIPE_CUSTOMER_SUCCESS';
export const STRIPE_CUSTOMER_ERROR = 'app/CheckoutPage/STRIPE_CUSTOMER_ERROR';

export const FETCH_LINE_ITEMS_REQUEST = 'app/ListingPage/FETCH_LINE_ITEMS_REQUEST';
export const FETCH_LINE_ITEMS_SUCCESS = 'app/ListingPage/FETCH_LINE_ITEMS_SUCCESS';
export const FETCH_LINE_ITEMS_ERROR = 'app/ListingPage/FETCH_LINE_ITEMS_ERROR';


export const SAVE_PAYPAL_TRACKING_REQUEST_SUCCESS = 'app/PayPalPayoutPage/SAVE_TRACKING_REQUEST_SUCCESS';

// ================ Reducer ================ //

const initialState = {
  listing: null,
  orderData: null,
  speculateTransactionInProgress: false,
  speculateTransactionError: null,
  speculatedTransaction: null,
  transaction: null,
  initiateOrderError: null,
  confirmPaymentError: null,
  stripeCustomerFetched: false,  
  accessTokenResponse: {},
  currentUser: {},
  payPalAPIRootURL: config?.payPal?.baseURL,
  payPalCheckInProgress: true,
  isPayPalAccountConnected: false,
  sellerPayPalMerchantId: null,
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SPECULATE_TRANSACTION_REQUEST:
      return {
        ...state,
        speculateTransactionInProgress: true,
        speculateTransactionError: null,
        speculatedTransaction: null,
      };
    case SPECULATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculatedTransaction: payload.transaction,
      };
    case SPECULATE_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculateTransactionError: payload,
      };

    case SAVE_ACCESS_TOKEN_REQUEST_SUCCESS:
      return { ...state, ...payload };
    case INITIATE_ORDER_REQUEST:
      return { ...state, initiateOrderError: null };
    case INITIATE_ORDER_SUCCESS:
      return { ...state, transaction: payload };
    case INITIATE_ORDER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, initiateOrderError: payload };

    case CONFIRM_PAYMENT_REQUEST:
      return { ...state, confirmPaymentError: null };
    case CONFIRM_PAYMENT_SUCCESS:
      return state;
    case CONFIRM_PAYMENT_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, confirmPaymentError: payload };
    
    case CONFIRM_PAYPAL_PAYMENT_REQUEST:
      return { ...state, confirmPaymentError: null };
    case CONFIRM_PAYPAL_PAYMENT_SUCCESS:
      return state;
    case CONFIRM_PAYPAL_PAYMENT_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, confirmPaymentError: payload };

    case STRIPE_CUSTOMER_REQUEST:
      return { ...state, stripeCustomerFetched: false };
    case STRIPE_CUSTOMER_SUCCESS:
      return { ...state, stripeCustomerFetched: true };
    case STRIPE_CUSTOMER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, stripeCustomerFetchError: payload };

    case SAVE_PAYPAL_TRACKING_REQUEST_SUCCESS:
      return { ...state, ...payload }; 

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const setInitialValues = initialValues => (
  {
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

const initiateOrderRequest = () => ({ type: INITIATE_ORDER_REQUEST });

const initiateOrderSuccess = order => ({
  type: INITIATE_ORDER_SUCCESS,
  payload: order,
});

const initiateOrderError = e => ({
  type: INITIATE_ORDER_ERROR,
  error: true,
  payload: e,
});

export const initiate_transaction_forPaymentRequest = () => ({type: INITIATE_TRANSACTION_FOR_PAYMENT_REQUEST});
export const initiate_transaction_forPaymentSuccess = (transaction) => ({
  type: INITIATE_TRANSACTION_FOR_PAYMENT_SUCCESS,
  payload: transaction,
});
export const initiate_transaction_forPaymentError = (error) => ({
  type: INITIATE_TRANSACTION_FOR_PAYMENT_ERROR,
  error: true,
  payload: error,
});

const confirmPaymentRequest = () => ({ type: CONFIRM_PAYMENT_REQUEST });

const confirmPaymentSuccess = orderId => ({
  type: CONFIRM_PAYMENT_SUCCESS,
  payload: orderId,
});

const confirmPaymentError = e => ({
  type: CONFIRM_PAYMENT_ERROR,
  error: true,
  payload: e,
});

const confirmPaypalOrderRequest = () => ({ type: CONFIRM_PAYPAL_PAYMENT_REQUEST });
const confirmPaypalPaymentSuccess = orderId => ({
  type: CONFIRM_PAYPAL_PAYMENT_SUCCESS,
  payload: orderId,
});
const confirmPaypalPaymentError = e => ({
  type: CONFIRM_PAYPAL_PAYMENT_ERROR,
  error: true,
  payload: e,
});

export const speculateTransactionRequest = () => ({ type: SPECULATE_TRANSACTION_REQUEST });

export const speculateTransactionSuccess = transaction => ({
  type: SPECULATE_TRANSACTION_SUCCESS,
  payload: { transaction },
});

export const speculateTransactionError = e => ({
  type: SPECULATE_TRANSACTION_ERROR,
  error: true,
  payload: e,
});

export const sendMessageError = e => ({
  type: SEND_MESSAGE_ERROR,
  error: true,
  payload: e,
});

export const saveIsPaypalConnectedStatus = (paypalConnectionStates) => ({
  type: SAVE_PAYPAL_TRACKING_REQUEST_SUCCESS,
  payload: paypalConnectionStates,
});

export const stripeCustomerRequest = () => ({ type: STRIPE_CUSTOMER_REQUEST });
export const stripeCustomerSuccess = () => ({ type: STRIPE_CUSTOMER_SUCCESS });
export const stripeCustomerError = e => ({
  type: STRIPE_CUSTOMER_ERROR,
  error: true,
  payload: e,
});

export const fetchLineItemsRequest = () => ({ type: FETCH_LINE_ITEMS_REQUEST });
export const fetchLineItemsSuccess = lineItems => ({
  type: FETCH_LINE_ITEMS_SUCCESS,
  payload: lineItems,
});
export const fetchLineItemsError = error => ({
  type: FETCH_LINE_ITEMS_ERROR,
  error: true,
  payload: error,
});

let currentListing = null;
let currentAuthor = null;
let paypalAccessToken = null;
let paypalConnectionStatus = null;

export const loadData = (listing) => (dispatch, getState, sdk) => {
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.

  if(!config.payPal.baseURL) {
    console.log('error', 'PayPal API Root URL has not been configured');
  }

  if (listing) {
    currentListing = ensureListing(listing)
    currentAuthor = ensureUser(currentListing.author);
  }

  return axios({
    method: 'post',
    url: 'https://' + config.payPal.baseURL + '/v1/oauth2/token',
    headers: {
      'Accept': 'application/json',
      'Accept-Language': 'en_US',
      'Content-Type': 'application/x-www-form-urlencoded',
      'PayPal-Partner-Attribution-Id': config.payPal.BNCode,
    },
    auth: {
      username: config.payPal.clientId,
      password: config.payPal.clientSecret,
    },
    params: {
      grant_type: 'client_credentials',
    },
  }).then(response => {
    let expires_in = response?.data?.expires_in;

    // get the current time in seconds (converted from milliseconds)
    let current_date_in_seconds = Math.floor(Date.now() / 1000);
    let result = response.data;
    result.expiration_date = current_date_in_seconds + expires_in;

    let res = {'accessTokenResponse': result, 'currentUser': getState()?.user?.currentUser};

    paypalAccessToken = res;

    // track the connected paypal account status
    return axios({
      method: 'get',
      url: 'https://' + config.payPal.baseURL + '/v1/customer/partners/' 
        + config.payPal.partnerMerchantId + '/merchant-integrations/'
        + currentAuthor?.attributes?.profile?.publicData?.payPal?.merchantIdInPayPal,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer  ${paypalAccessToken?.accessTokenResponse?.access_token}`,
        'PayPal-Partner-Attribution-Id': config.payPal.BNCode,
      },
    })
    .then(response => {    
      if(response?.data?.payments_receivable && response?.data?.primary_email_confirmed 
        && response?.data?.oauth_integrations?.length > 0)
      {
        return { 
          ...paypalAccessToken,
          isPayPalAccountConnected: true,
          payPalCheckInProgress: false,
          sellerPayPalMerchantId: currentAuthor?.attributes?.profile?.publicData?.payPal?.merchantIdInPayPal
        };
      }
      else {
        return { ...paypalAccessToken, isPayPalAccountConnected: false, payPalCheckInProgress: false };
      }
    })
  })
};

export const saveShippingAddress = (addressData) => (dispatch, getState, sdk) => {
  return sdk.currentUser.updateProfile({ protectedData: {
    ...addressData
  } })
}

/* ================ Thunks ================ */

export const initiateOrder = (orderParams, transactionId) => (dispatch, getState, sdk) => {
  dispatch(initiateOrderRequest());

  // If we already have a transaction ID, we should transition, not
  // initiate.
  const isTransition = !!transactionId;

  const transition = orderParams?.protectedData?.selectedPaymentMethod == 'paypal' ?
    isTransition
      ? TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY_PAYPAL
      : TRANSITION_REQUEST_PAYMENT_PAYPAL
    : 
    isTransition
    ? TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY
    : TRANSITION_REQUEST_PAYMENT;

  const isPrivilegedTransition = isPrivileged(transition);
  
  const orderData = orderParams;
  
  const { quantity } = orderData;
  
  const quantityMaybe = quantity ? { stockReservationQuantity: quantity } : {};
  //const bookingParamsMaybe = bookingDates || {};

  // Parameters for Flex API
  const transitionParams = {
    ...quantityMaybe,
    ...orderData
  };

  const bodyParams = isTransition
    ? {
        id: transactionId,
        transition,
        params: transitionParams,
      }
    : {
        processAlias: config.transactionProcessAlias,
        transition,
        params: transitionParams,
      };
  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };
  

  const handleSuccess = response => {
    const entities = denormalisedResponseEntities(response);
    const order = entities[0];
    dispatch(initiateOrderSuccess(order));
    dispatch(fetchCurrentUserHasOrdersSuccess(true));
    return order;
  };

  const handleError = e => {
    dispatch(initiateOrderError(storableError(e)));
    const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};
    log.error(e, 'initiate-order-failed', {
      ...transactionIdMaybe,
      listingId: orderParams.listingId.uuid,
      ...orderData,
    });
    throw e;
  };

  if (isTransition && isPrivilegedTransition) {
    // transition privileged
    return transitionPrivileged({ isSpeculative: false, orderData, bodyParams, queryParams })
      .then(handleSuccess)
      .catch(handleError);
  } else if (isTransition) {
    // transition non-privileged
    return sdk.transactions
      .transition(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  } else if (isPrivilegedTransition) {
    // initiate privileged
    return initiatePrivileged({ isSpeculative: false, orderData, bodyParams, queryParams })
      .then(handleSuccess)
      .catch(handleError);
  } else {
    // initiate non-privileged
    return sdk.transactions
      .initiate(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  }
};

export const confirmPayment = orderParams => (dispatch, getState, sdk) => {
  dispatch(confirmPaymentRequest());

  const bodyParams = {
    id: orderParams.transactionId,
    transition: orderParams?.attributes?.protectedData?.selectedPaymentMethod == 'paypal' ?
      TRANSITION_CONFIRM_PAYMENT_PAYPAL : TRANSITION_CONFIRM_PAYMENT,
    params: {},
  };

  return sdk.transactions
    .transition(bodyParams)
    .then(response => {
      const order = response.data.data;
      dispatch(confirmPaymentSuccess(order.id));
      return order;
    })
    .catch(e => {
      dispatch(confirmPaymentError(storableError(e)));
      const transactionIdMaybe = orderParams.transactionId
        ? { transactionId: orderParams.transactionId.uuid }
        : {};
      log.error(e, 'initiate-order-failed', {
        ...transactionIdMaybe,
      });
      throw e;
    });
};

export const sendMessage = params => (dispatch, getState, sdk) => {
  
  const {
    message,
    listing_id,
    id,
    orderData,
    intl,
    currentUser,
  } = params;
  
  const orderId = id;
  const customer = currentUser;
  let offer_price = orderData?.offer_price;
  const size = orderData?.size;

  if (offer_price != null || offer_price != undefined) 
  {
    offer_price = formatMoney(intl, offer_price);
    let offers = [];
    let publicData = {};

    offers = updateUsedCustomerOffer(listing_id, customer, 'used', offer_price);

    offers[1]? publicData = { ...customer?.attributes?.profile?.publicData, offers: [...offers]} : null;

    offers[1]? (
      // save offer to user profile
      sdk.currentUser.updateProfile({ publicData })
      .then(response => {
      })
      .catch(e => {
        dispatch( sendMessageError(storableError(e)));
        // Rethrow so the page can track whether the saving failed, and
        throw e;
      })
    ) : null;
  }

  if (message) {
    return sdk.messages
      .send({ transactionId: orderId, content: message })
      .then(() => {
        return { orderId, messageSuccess: true };
      })
      .catch(e => {
        log.error(e, 'initial-message-send-failed', { txId: orderId });
        return { orderId, messageSuccess: false };
      });
  } else {
    return Promise.resolve({ orderId, messageSuccess: true });
  }
};

/**
 * Initiate or transition the speculative transaction with the given
 * booking details
 *
 * The API allows us to do speculative transaction initiation and
 * transitions. This way we can create a test transaction and get the
 * actual pricing information as if the transaction had been started,
 * without affecting the actual data.
 *
 * We store this speculative transaction in the page store and use the
 * pricing info for the booking breakdown to get a proper estimate for
 * the price with the chosen information.
 */
export const speculateTransaction = (orderParams, transactionId, paymentMethod) => (dispatch, getState, sdk) => {
  dispatch(speculateTransactionRequest());

  // If we already have a transaction ID, we should transition, not
  // initiate.
  const isTransition = !!transactionId;
  const transition = paymentMethod == 'paypal' ?
  isTransition
    ? TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY_PAYPAL
    : TRANSITION_REQUEST_PAYMENT_PAYPAL
  : 
  isTransition
  ? TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY
  : TRANSITION_REQUEST_PAYMENT;
  
  const isPrivilegedTransition = isPrivileged(transition);

  const { orderData, ...otherOrderParams } = orderParams;

  // Parameters for Flex API
  const transitionParams = {
    ...orderData,
    ...otherOrderParams,
    cardToken: 'CheckoutPage_speculative_card_token',
  };

  const bodyParams = isTransition
    ? {
        id: transactionId,
        transition,
        params: transitionParams,
      }
    : {
        processAlias: config.transactionProcessAlias,
        transition,
        params: transitionParams,
      };

  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const handleSuccess = response => {
    const entities = denormalisedResponseEntities(response);
    if (entities.length !== 1) {
      throw new Error('Expected a resource in the speculate response');
    }
    const tx = entities[0];
    dispatch(speculateTransactionSuccess(tx));
  };

  const handleError = e => {
    log.error(e, 'speculate-transaction-failed', {
      listingId: transitionParams.listingId.uuid,
      orderData,
    });
    return dispatch(speculateTransactionError(storableError(e)));
  };
  
  if (isTransition && isPrivilegedTransition) {
    // transition privileged
    return transitionPrivileged({ isSpeculative: true, orderData, bodyParams, queryParams })
      .then(handleSuccess)
      .catch(handleError);
  } else if (isTransition) {
    // transition non-privileged
    return sdk.transactions
      .transitionSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  } else if (isPrivilegedTransition) {
    // initiate privileged
    return initiatePrivileged({ isSpeculative: true, orderData, bodyParams, queryParams })
      .then(handleSuccess)
      .catch(handleError);
  } else {
    // initiate non-privileged
    return sdk.transactions
      .initiateSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  }
};

// StripeCustomer is a relantionship to currentUser
// We need to fetch currentUser with correct params to include relationship
export const stripeCustomer = () => (dispatch, getState, sdk) => {
  dispatch(stripeCustomerRequest());

  return dispatch(fetchCurrentUser({ include: ['stripeCustomer.defaultPaymentMethod'] }))
    .then(response => {
      dispatch(stripeCustomerSuccess());
    })
    .catch(e => {
      dispatch(stripeCustomerError(storableError(e)));
    });
};

/* export const initiate_transaction_forPayment = (listingId, orderData) => (dispatch, getState, sdk) => {
  dispatch(initiate_transaction_forPaymentRequest());

  const bodyParams = {
    transition: TRANSITION_REQUEST_PAYMENT,
    processAlias: config.transactionProcessAlias,
    params: { listingId },
  };

  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  return initiatePrivileged({ isSpeculative: true, orderData, bodyParams, queryParams })
      .then(response => {
        return response.data.data;
      })
      .catch(
        e => {
          dispatch(initiate_transaction_forPaymentError(storableError(e)));
          throw e;
        }
      );
}; */

export const fetchTransactionLineItems = (orderData, listingId, isOwnListing) => (dispatch) => {
  dispatch(fetchLineItemsRequest());
  return new Promise((resolve, reject) => {
    resolve(transactionLineItems({ orderData, listingId, isOwnListing }));
  }).then(response => {
    dispatch(fetchLineItemsSuccess(response));
    const lineItems = response.data;
    return lineItems;
  }).catch(e => {
    dispatch(fetchLineItemsError(storableError(e)));
    log.error(e, 'fetching-line-items-failed', {
        listingId: listingId.uuid,
        orderData,
      });
    console.log(e, 'error fetching line items');
  });
};

/* export const confirmPaypalPayment = (paymentId, payerId, transactionId) => (dispatch, getState, sdk) => {
  /* curl -v -X GET https://api-m.sandbox.paypal.com/v2/checkout/orders/5O190127TN364715T \
  -H "Content-Type: application/json" \
  -H "Authorization: Bearer <Access-Token>" */
//};

export const confirmPaypalPayment = orderParams => (dispatch, getState, sdk) => {
  dispatch(confirmPaypalOrderRequest());

  return axios(
    {
      method: 'get',
      url: 'https://' + config.payPal.baseURL + '/v2/checkout/orders/' + orderParams.paypalOrderId,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + getState()?.CheckoutPage?.accessTokenResponse?.access_token,
        'PayPal-Partner-Attribution-Id': config.payPal.BNCode,
      },
    })
    .then(response => {
      if (response.data.status === 'COMPLETED') {
        dispatch(confirmPaypalPaymentSuccess(response.data));
        return response.data;
      }
    })
    .catch(e => {
      dispatch(confirmPaypalPaymentError(storableError(e)));
    });
};
